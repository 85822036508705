import React from 'react';
import { Row, Table, flexRender, ColumnDef } from '@tanstack/react-table';
import styles from './CustomViewsTable.module.scss';
import { Virtualizer } from '@tanstack/react-virtual';

import { MIN_CELL_WIDTH } from '../SpotonDataTable/constants';

interface CustomViewsTableProps {
  table: Table<any>;
  rows: Row<any>[];
  dragOverId: string;
  dragDirection: string;
  dragStart: string;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  pagination: { pageIndex: number; pageSize: number };
}

type CustomColumnDef = ColumnDef<any, any> & {
  accessorKey?: string;
  so_fixedWidth?: number;
};

export function CustomViewsTable({ table, rows, dragOverId, dragDirection, dragStart }: CustomViewsTableProps) {
  const getCellClassName = (accessorKey: string, dataSource: string): string => {
    if (accessorKey === 'dataSource') {
      switch (dataSource.toLowerCase()) {
        case 'orders':
          return `${styles.DataSourceCell} ${styles.DataSourceOrders}`;
        case 'items':
          return `${styles.DataSourceCell} ${styles.DataSourceItems}`;
        case 'payments':
          return `${styles.DataSourceCell} ${styles.DataSourcePayments}`;
        case 'menu':
          return `${styles.DataSourceCell} ${styles.DataSourceMenu}`;
        case 'employees':
          return `${styles.DataSourceCell} ${styles.DataSourceEmployees}`;
        default:
          return styles.DataSourceCell;
      }
    }

    switch (accessorKey) {
      case 'description':
        return styles.DescriptionCell;
      case 'dateCreated':
        return styles.DateCell;
      case 'name':
        return styles.ViewNameCell;
      case 'createdBy':
        return styles.CreatedByCell;
      default:
        return '';
    }
  };
  const formatDate = (dateString: string) => {
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    } catch {
      return dateString;
    }
  };

  // Filter out the columns we want to hide
  const visibleHeaders = table.getHeaderGroups()[0].headers.filter((header: any) => {
    const columnId = (header?.column?.columnDef as CustomColumnDef)?.accessorKey;
    return !['startDate', 'endDate', 'sharedWith', 'categoryList', 'emailList'].includes(columnId);
  });

  return (
    <table className={styles.SpotonTable}>
      <thead className={styles.Head}>
        <tr className={styles.HeaderRow} style={{ display: 'flex', width: '100%' }}>
          {visibleHeaders.map((header: any) => {
            const colDef = header?.column?.columnDef as CustomColumnDef;
            return (
              <th
                id={colDef.accessorKey}
                className={`${styles.HeaderCell} ${
                  dragOverId === colDef.accessorKey && colDef.accessorKey !== dragStart
                    ? dragDirection === 'left'
                      ? styles.DraggedCellLeft
                      : styles.DraggedCellRight
                    : ''
                }`}
                key={header.id}
                style={{
                  width: header.getSize(),
                  minWidth: header.column.columnDef.so_fixedWidth ?? MIN_CELL_WIDTH,
                  maxWidth: header.column.columnDef.so_fixedWidth ?? undefined,
                }}
              >
                {header.column.columnDef.so_oldHeader}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={styles.TableBody}>
        {rows.map((row) => {
          return (
            <tr className={styles.TableRow} key={row.id} style={{ display: 'flex', width: '100%' }}>
              {row.getVisibleCells().map((cell) => {
                const colDef = cell.column.columnDef as CustomColumnDef;
                const dataSource = row.original.dataSource;
                const cellClassName = getCellClassName(colDef.accessorKey, dataSource);
                if (['startDate', 'endDate', 'categoryList', 'emailList'].includes(colDef.accessorKey)) {
                  return null;
                }

                const value = cell.getValue();

                let content = flexRender(cell.column.columnDef.cell, cell.getContext());
                if (colDef.accessorKey?.includes('date')) {
                  content = formatDate(value as string);
                }

                return (
                  <td
                    className={`${styles.Cell} ${cellClassName}`}
                    key={cell.id}
                    style={{
                      minWidth: colDef.so_fixedWidth ?? MIN_CELL_WIDTH,
                      maxWidth: colDef.so_fixedWidth ?? undefined,
                      width: cell.column.getSize(),
                    }}
                  >
                    <div className={styles.CellContentContainer}>
                      <div className={styles.CellContentContainerInner}>{content}</div>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
