import { Table } from '@tanstack/react-table';
import { DebouncedInput } from './DebouncedInput';

export function TextFilter({ column }: { table: Table<any>; column: any; serverMode: boolean }) {
  const columnFilterValue = column.getFilterValue() ?? {
    filterType: 'text',
    params: { search: '' },
  };

  return (
    <>
      <div>
        <DebouncedInput
          label="Search..."
          type="text"
          value={columnFilterValue.params.search}
          onChange={(value: any) => {
            column.setFilterValue({ ...columnFilterValue, params: { search: value } });
          }}
          placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        />
      </div>
    </>
  );
}
