import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'app/components/App/App.store';

import { isMobile } from 'features/common/utils/responsive.utils';
import { CustomViewsDesktop } from './CustomViewsDesktop.component';
import { CustomViewsMobile } from './CustomViewsMobile.component';

import { getConfigVar } from 'features/common';
import styles from './CustomViews.module.scss';
import { LoadingIndicator } from 'spoton-lib';
import { bulkRemoveCustomViews, bulkUpdateCustomViews, customViews } from 'app/components/App/App.hunks';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FAKE_CUSTOM_VIEWS_DATA, isFeatureFlagEnabled } from 'features/common/utils/featureFlags';

// this component will guarnantee that the store is loaded with the necessary data before rendering
// the custom views
export function CustomViews() {
  const dispatch = useDispatch<AppDispatch>();

  const [searchParams, setSearchParams] = useSearchParams();

  const reload = searchParams.get('reload');

  // old way of refreshing the custom views
  const customViewsLandingRefresh = useSelector((state: RootState) => state.app.customViewsLandingRefresh);

  const dataSourcesLoading = useSelector((state: RootState) => state.app.dataSourcesLoading);
  const customViewsLoading = useSelector((state: RootState) => state.app.customViewsLoading);
  const locationsLoading = useSelector((state: RootState) => state.app.locationsLoading);

  const customViewData = useSelector((state: RootState) => state.app.customViewsData);

  const customViewsError = useSelector((state: RootState) => state.app.customViewsError);
  const dataSourcesError = useSelector((state: RootState) => state.app.dataSourcesError);
  const locationsError = useSelector((state: RootState) => state.app.locationsError);

  const error = customViewsError || dataSourcesError || locationsError;
  const appLoading = dataSourcesLoading || customViewsLoading || locationsLoading;

  const pathSuffix = isFeatureFlagEnabled(FAKE_CUSTOM_VIEWS_DATA) ? '-fake' : '';
  useEffect(() => {
    if (reload == '1') {
      dispatch(
        customViews({
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/${pathSuffix}`,
          headers: { 'Content-Type': 'application/json' },
          //cacheKey: 'custom-views',
          //cacheDuration: 10 * 1000,
          credentials: 'include',
        }),
      );
      setSearchParams();
    }
  }, [dispatch, reload, pathSuffix, setSearchParams]);

  useEffect(() => {
    if (customViewsLandingRefresh !== 0) {
      dispatch(
        customViews({
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/${pathSuffix}`,
          headers: { 'Content-Type': 'application/json' },
          //cacheKey: 'custom-views',
          //cacheDuration: 10 * 1000,
          credentials: 'include',
        }),
      );
    }
  }, [customViewsLandingRefresh, dispatch, pathSuffix]);

  const onRemoveCustomViews = (idsToRemove) => {
    dispatch(
      bulkRemoveCustomViews({
        path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/remove-custom-views`,
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ idsToRemove }),
      }),
    );
  };

  const onUpdateCustomViews = (customViews) => {
    dispatch(
      bulkUpdateCustomViews({
        path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/update-custom-views`,
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ customViews }),
      }),
    );
  };

  if (error) return <p>Error: {error}</p>;

  if (appLoading) {
    return (
      <div className={styles.LoaderContainer}>
        <div className={styles.LoadingText}>{'Loading Saved Custom Views'}</div>
        <div className={styles.Loading}>
          <LoadingIndicator size="sm" greyAnimation />
        </div>
      </div>
    );
  }

  return isMobile() ? (
    <CustomViewsMobile customViewData={customViewData} />
  ) : (
    <>
      <CustomViewsDesktop onRemoveCustomViews={onRemoveCustomViews} onUpdateCustomViews={onUpdateCustomViews} />
    </>
  );
}

export default CustomViews;
