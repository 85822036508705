// [startDate, endDate, dataSource, location];

import moment from 'moment';
import { getRows } from './App.hunks';
import { CUSTOM_VIEW_MODE, getDefaultStartDate } from './App.slice';

// any of these will trigger a row fetch on current custom view
const ROW_FETCHER_ACTIONS = ['app/setAppLoaded', 'app/setDateRange', 'app/setLocation'];

// these actions are the ones that are outsde the column chooser modal
const CONDITIONAL_ROW_FETCHER_ACTIONS = ['app/setFilters', 'SORT_COLUMN', 'app/incrementPage', 'app/decrementPage'];

const CONDITIONAL_COLUMN_CHOOSER_ROW_FETCHER_ACTION = 'app/saveColumnChooserConfig';
// this middleware will listen for certain actions and trigger a row fetch
// these actions will fetch rows regardless of the current mode
export const rowFetcherMiddleware = (store) => (next) => async (action) => {
  console.log('DEBUG88 here action type= ', action.type);

  if (ROW_FETCHER_ACTIONS.includes(action.type)) {
    const currentState = store.getState();

    console.log('DEBUG8 got row fetcher action: ', action);

    // values in the action will write over the top of the currentCustomView
    const { currentCustomView } = currentState.app;

    switch (action.type) {
      case 'app/setAppLoaded':
        console.log('DEBUG8 setAppLoaded BEFORE DISPATCH: ', currentCustomView);
        store.dispatch(getRows({ ...currentCustomView, initialFetch: true }));
        break;
      case 'app/setDateRange':
        console.log('DEBUG8 setDateRange BEFORE DISPATCH: ', {
          ...currentCustomView,
          ...action.payload,
          pageIndex: 0,
          initialFetch: true,
        });
        store.dispatch(getRows({ ...currentCustomView, ...action.payload, pageIndex: 0, initialFetch: true }));
        break;
    }
  }
  return next(action);
};

// we will have another class of actions that will trigger a row fetch
// but these will be conditional on the current mode
// for example, if we are in clientmode
// changes to the filter will not result in a row fetch
// but if we are in server mode, they will.

// NOTE: there are only two actions we care about. THat is setFilters
// and setSort. In client mode it's fine to filter and sort on the
// rows because we have all rows in memory anyways. But in server mode
// we need to pass let the server do the filtering and sorting.

export const conditionalRowFetcherMiddleware = (store) => (next) => async (action) => {
  console.log('DEBUG88 here2 action type= ', action.type);

  const currentState = store.getState();
  if (!currentState.app.appLoaded) return next(action);

  if (CONDITIONAL_ROW_FETCHER_ACTIONS.includes(action.type)) {
    if (currentState.app.mode === 'client') {
      // let this flow to the reducers
      return next(action);
    }

    console.log('DEBUG88 got a conditional row fetcher action: ', action);

    // if (currentState.app.getRowsLoading) {
    //   return next(action);
    // }

    // values in the action will write over the top of the currentCustomView

    const { currentCustomView } = currentState.app;

    if (action.type === 'SORT_COLUMN') {
      const column = action.payload;

      const columnSortCopy = JSON.parse(JSON.stringify(currentCustomView.columnSort ?? []));

      const columnColSort = columnSortCopy.find((i) => i.id === column);

      console.log('DEBUG88 found columnColSort = ', columnColSort);

      if (columnColSort == null) {
        columnSortCopy.push({
          id: column,
          desc: true,
        });
      } else if (columnColSort.desc) {
        console.log('DEBUG88 columnColSort.desc = true');
        columnColSort.desc = false;
      } else {
        // remove the column from the sort
        const index = columnSortCopy.findIndex((i) => i.id === column.id);
        columnSortCopy.splice(index, 1);
      }
      console.log('DEBUG88 columnSortCopy=', columnSortCopy);
      console.log('DEBUG88 SORT_COLUMN before dispatch ', currentCustomView);
      store.dispatch({ type: 'app/setColumnSort', payload: columnSortCopy });
      store.dispatch(getRows({ ...currentCustomView, columnSort: columnSortCopy, pageIndex: 0 }));
      // by returning we are swallowing the action
      return;
    } else if (action.type === 'app/setFilters') {
      console.log('DEBUG8 setFilters filters =  ', action);
      store.dispatch(getRows({ ...currentCustomView, filters: action.payload, pageIndex: 0 }));
      // by returning we are swallowing the action
      return;
    } else if (action.type === 'app/decrementPage' || action.type === 'app/incrementPage') {
      let page;
      const pageIndex = Number(currentState?.app?.currentCustomView?.pageIndex ?? 0);
      console.log('DEBUG999  pageIndex', pageIndex);
      const pageCount = Number(currentState?.app?.pageCount ?? 0);
      console.log('DEBUG999 pageCount=', pageCount);

      if (action.type === 'app/decrementPage') {
        console.log('DEBUG999 decrementPage pageIndex=', pageIndex);
        if (pageIndex >= 0) {
          console.log('DEBUG999 setting page', pageIndex);
          page = pageIndex - 1;
        }
      } else if (action.type === 'app/incrementPage') {
        console.log('DEBUG999 increment pageIndex=', pageIndex);

        if (pageCount > 0 && pageIndex < pageCount) {
          console.log('DEBUG999 setting page', pageIndex);
          page = pageIndex + 1;
        }
      }

      if (page != null) {
        store.dispatch(getRows({ ...currentCustomView, pageIndex: page }));
      }

      // by returning we are swallowing the action
      return;
    }
  }
  return next(action);
};

// this will handle the column chooser modal save button
// this is where the datasource and columns are changed
// its possible that the user was in client mode and made some changes
// the columns without changing the datasource. In this case we can
// ignore the change. But if were in server mode, we need to fetch.

export const condtionalColumnChooserFetcherMiddleware = (store) => (next) => async (action) => {
  if (action.type === CONDITIONAL_COLUMN_CHOOSER_ROW_FETCHER_ACTION) {
    console.log('DEBUG8 condtionalColumnChooserFetcherMiddleware  fetcher action: ', action);

    let isFetchNeeded = false;
    const currentState = store.getState();

    const { currentCustomView } = currentState.app;

    // when were in client mode, we only need to fetch if the datasource changed.
    if (currentState.app.mode === CUSTOM_VIEW_MODE.CLIENT) {
      if (currentState.app.currentCustomView.dataSource !== currentState.app.chosenDataSource) {
        isFetchNeeded = true;
      }
    } else {
      // if were not in client mode we fetch always
      isFetchNeeded = true;
    }

    if (isFetchNeeded) {
      console.log('DEBUG8 condtionalColumnChooserFetcherMiddleware before dispatch: ', action);
      store.dispatch(
        getRows({
          dataSource: currentState.app.chosenDataSource,
          chosenColumns: currentState.app.chosenColumns,
          columnOrder: currentState.app.columnOrder,
          startDate: currentCustomView.startDate ?? getDefaultStartDate(),
          endDate: currentCustomView.endDate ?? moment().toDate(),
          selectedRange: currentState.app.selectedRange,
          filters: [],
          initialFetch: true,
          pageIndex: 0,
        }),
      );
    } else {
      console.log('DEBUG8 condtionalColumnChooserFetcherMiddleware no need tp fetch: ', action);
    }
  }

  return next(action);
};
