/* eslint-disable @typescript-eslint/naming-convention */
import { useDispatch, useSelector } from 'react-redux';
import { DebouncedInput } from './DebouncedInput';
import { SpotOnTanstackColDefs } from './types';
import { getConfigVar } from 'features/common/utils';
import { useEffect } from 'react';
import { AppDispatch } from 'app/components/App/App.store';
import { FAKE_CUSTOM_VIEWS_DATA, isFeatureFlagEnabled } from 'features/common/utils/featureFlags';
import { getColumnMinMax } from 'app/components/App/App.hunks';

const getMinMax = (rows: any[], accessorKey: string) => {
  const values = rows.map((row) => row.getValue(accessorKey));
  return [Math.min(...values), Math.max(...values)];
};

export function NumberFilter({ column, table, serverMode }: { column: any; table: any; serverMode: boolean }) {
  const dispatch = useDispatch<AppDispatch>();

  const filters = useSelector((state) => (state as any).app.currentCustomView.filters);
  const dataSource = useSelector((state) => (state as any).app.currentCustomView.dataSource);
  const startDate = useSelector((state) => (state as any).app.currentCustomView.startDate);
  const endDate = useSelector((state) => (state as any).app.currentCustomView.endDate);
  const loading = useSelector((state) => (state as any).app.getColumnMinMaxLoading);
  const error = useSelector((state) => (state as any).app.getColumnMinMaxError);
  const data = useSelector((state) => (state as any).app.getColumnMinMaxData);
  const chosenColumns = useSelector((state) => (state as any).app.currentCustomView.chosenColumns);

  const so_params: SpotOnTanstackColDefs<any> = column.columnDef as SpotOnTanstackColDefs<any>;

  const timing = so_params.so_filterTiming ?? 'preAgg';

  console.log('serverMode', serverMode);

  let min = 0,
    max = 0;
  if (serverMode) {
    console.log('todo: implement server mode');
  } else {
    if (timing === 'postAgg') {
      [min, max] = getMinMax(table.getRowModel().rows, column.id);
    } else {
      min = Number(column.getFacetedMinMaxValues()?.[0] ?? '');
      max = Number(column.getFacetedMinMaxValues()?.[1] ?? '');
    }
  }

  useEffect(() => {
    if (serverMode) {
      const pathSuffix = isFeatureFlagEnabled(FAKE_CUSTOM_VIEWS_DATA) ? '-fake' : '';

      dispatch(
        getColumnMinMax({
          method: 'POST',
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views-data/column-min-max${pathSuffix}`,
          body: JSON.stringify({ dataSource, startDate, endDate, filters, columnId: column.id, chosenColumns }),
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!serverMode) {
    return <NumberFilterWithMinMax column={column} table={table} serverMode={serverMode} min={min} max={max} />;
  }

  if (error) {
    return <> Error loading column values</>;
  }

  if (loading) {
    return <> Loading column values</>;
  }

  console.log('DEBUG77 data', data);

  return <NumberFilterWithMinMax column={column} table={table} serverMode={serverMode} min={data.min} max={data.max} />;
}

export function NumberFilterWithMinMax({
  column,
  table,
  serverMode,
  min,
  max,
}: {
  column: any;
  table: any;
  serverMode: boolean;
  min: number;
  max: number;
}) {
  // check to see if the column has a value formatter function
  const so_params: SpotOnTanstackColDefs<any> = column.columnDef as SpotOnTanstackColDefs<any>;
  const timing = so_params.so_filterTiming ?? 'preAgg';

  console.log('serverMode', serverMode, table);

  // some day it will be great if user can choose the timing with a checkbox
  //  but for now we derive timing from column definition metadata
  const columnFilterValue = column.getFilterValue() ?? {
    filterType: 'inclusiveNumericRange',
    params: { min: '', max: '', filterTiming: timing },
  };

  let newMinFormatted = null;
  let newMaxFormatted = null;

  // const min = timing === 'preAgg' ? Number(column.getFacetedMinMaxValues()?.[0] ?? '') : Number(postAggMin);
  // const max = timing === 'preAgg' ? Number(column.getFacetedMinMaxValues()?.[1] ?? '') : Number(postAggMax);

  if (so_params.so_valueFormatter != null) {
    const options = so_params.so_INTLNumberFormatOptions;
    const locale = so_params.so_localeCode ?? 'en-US';
    const formatter = new Intl.NumberFormat(locale, options);

    newMinFormatted = formatter.format(min);
    newMaxFormatted = formatter.format(max);
  } else {
    newMinFormatted = min;
    newMaxFormatted = max;
  }

  const minPlaceHolderValue = `${newMinFormatted}`;
  const maxPlaceHolderValue = `${newMaxFormatted}`;

  return (
    <>
      <DebouncedInput
        label="Min"
        name="min"
        type="number"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        value={columnFilterValue.params.min}
        onChange={(value) => {
          const { params } = columnFilterValue;
          const newValue = { ...params, min: value };
          column.setFilterValue({ ...columnFilterValue, params: newValue });
        }}
        placeholder={minPlaceHolderValue}
      />

      <DebouncedInput
        label="Max"
        type="number"
        name="max"
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        value={columnFilterValue.params.max}
        onChange={(value) => {
          const { params } = columnFilterValue;
          const newValue = { ...params, max: value };
          column.setFilterValue({ ...columnFilterValue, params: newValue });
        }}
        placeholder={maxPlaceHolderValue}
      />
    </>
  );
}
