import { useRef } from 'react';
import { IconButton, Popover, colors, RowButton, Chips, Icon } from 'spoton-lib';

import { FilterFactory } from './FilterFactory';
import styles from './FilterItem.module.scss';
import { useOuterClick } from './hooks/useOuterClick';
import { PrivateColumnDefs } from './types';

// This wraps the Popover and handles on outside click events so it can dismiss itself.
export function FilterItem({
  table,
  columnDefs,
  index,
  onRemoveFilter,
  filterState,
  onAddFilter,
  updateFilterState,
  onClearInactiveFilter,
  isLastFilter,
  serverMode,
}: {
  table: any;
  columnDefs: PrivateColumnDefs<any>[];
  index: number;
  onRemoveFilter: (column: string, index: number) => void;
  onAddFilter: (columnName: string) => void;
  filterState: any;
  updateFilterState: (index: number, state: any) => void;
  onClearInactiveFilter: (index: number) => void;
  isLastFilter: boolean;
  serverMode: boolean;
}) {
  const { columnFilters } = table.getState();
  const popoverRef = useRef<HTMLInputElement>(null);
  const popoverOpen = filterState?.popoverOpen;
  const activeColumn = filterState?.activeColumn;

  console.log('DEBUG10 filter item render', filterState);

  useOuterClick(() => {
    console.log('DEBUG10 outer click ');
    // a user might click on a column and then decide to bail out.
    // if this happens we should delete the filter
    // but we only do this if the filter has not been interacted with.
    // the moment a users interacts with the filter the only way the filter can be deleted
    // is if the user deletes it.

    // if our popover menu is not open ignore this click
    if (popoverOpen) {
      const { columnFilters } = table.getState();

      const exists = columnFilters.find((i: any) => i.id === activeColumn) != null;

      if (!exists) {
        if (!isLastFilter) {
          onClearInactiveFilter(index);
        } else {
          updateFilterState(index, { ...filterState, popoverOpen: false });
        }
      } else {
        updateFilterState(index, { ...filterState, popoverOpen: false });
      }
    }
  }, popoverRef);

  const columnClicked = (columnName: string) => {
    return () => {
      //setActiveColumn(columnName);

      onAddFilter(columnName);
    };
  };

  // this will be referenced later so we dont show a column that already has a filter on it
  const activeColumnFilters = columnFilters.reduce((acc: any, current: any) => {
    return { ...acc, [current.id]: [current.id] };
  }, {});

  const filteredColumnDefs = columnDefs.filter(
    (i) => activeColumnFilters[i.accessorKey] == null && i.so_filterFn != null,
  );

  const columnRowButtons = filteredColumnDefs.map((i) => (
    <RowButton
      arrow={true}
      className={styles.RowButton}
      key={i.accessorKey as string}
      title={i.so_oldHeader as string}
      onClick={columnClicked(i.accessorKey as string)}
      leftElement={<Icon name="FilterIcon" alt="FilterIcon" size={12} />}
    />
  ));

  const activeColumnObject = table.getAllLeafColumns().find((i: any) => i.columnDef.accessorKey === activeColumn);

  const columnDisplayName =
    activeColumn !== '' ? columnDefs.find((i) => i.accessorKey === activeColumn)?.so_oldHeader : '';

  const chip = (
    <div className={styles.FilterChipContainer}>
      <Chips
        className={activeColumn != null && !isLastFilter ? styles.FilterChipActive : styles.FilterChip}
        text={isLastFilter === true ? 'New Filter' : (columnDisplayName as string) ?? ''}
        onClick={() => {
          updateFilterState(index, { ...filterState, popoverOpen: !popoverOpen });
        }}
      />
      <IconButton
        className={styles.IconButton}
        disableBorder
        size={32}
        color={colors.black}
        name={!isLastFilter ? 'DeleteIcon' : 'AddIcon'}
        alt={!isLastFilter ? 'Delete' : 'Add'}
        onClick={() => {
          if (!isLastFilter) {
            onRemoveFilter(activeColumn, index);
          }
        }}
      />
    </div>
  );

  return (
    <div
      className={styles.FilterBarContainer}
      ref={popoverRef}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Popover
        className={styles.FilterBarContent}
        showArrow={true}
        variant="bottom"
        trigger={chip}
        isOpen={popoverOpen}
      >
        {activeColumn != null ? (
          <FilterFactory serverMode={serverMode} table={table} column={activeColumnObject} />
        ) : (
          columnRowButtons
        )}
      </Popover>
    </div>
  );
}
