// THIS COMPONENT IS NOT IN USE CURRENTLY

import React from 'react';
import { SpotOnTanstackColDefs } from './types';
import styles from './SpotonDataTable.module.scss';
import { getGroupingColumns } from 'features/common/utils/groupingUtils';

interface GroupingInfoProps {
  enableGrouping: boolean;
  chosenColumns: string[];
  columnDefs: SpotOnTanstackColDefs<any>[];
  colsCausingNoGrouping?: string[];
}

function getReadableGroupingColumns(selectedColumns: string[], coldefs: SpotOnTanstackColDefs<any>[]) {
  const groupingColumns = getGroupingColumns(selectedColumns, coldefs).filter!((i) => i !== 'globalId');
  const readableGroupingColumns = groupingColumns.map((i) => coldefs.find((j) => j.accessorKey === i)?.so_oldHeader);
  return readableGroupingColumns;
}

function GroupingInfo({ enableGrouping, chosenColumns, columnDefs, colsCausingNoGrouping }: GroupingInfoProps) {
  const readableGroupingColumns = getReadableGroupingColumns(chosenColumns, columnDefs).join(', ');

  return (
    <div className={styles.GroupingInfoRow}>
      {enableGrouping
        ? `Groupings Applied: ${readableGroupingColumns}`
        : `No Groupings Applied, because columns (${colsCausingNoGrouping?.join(', ')}) were set`}
    </div>
  );
}

export default GroupingInfo;

// ******************************************
// NOT USING THE BELOW CURRENTLY
// function _getDefaultGroupingInfo(groupingColumns: string[], columnDefs: SpotOnTanstackColDefs<any>[]) {
//   const readableGroupingColumns = groupingColumns
//     .filter((col) => col !== 'globalId')
//     .map((col) => columnDefs.find((def) => def.accessorKey === col)?.so_oldHeader)
//     .filter(Boolean);

//   return `Groupings Applied: ${readableGroupingColumns.join(', ')}`;
// }

// function _getDescriptiveGroupingInfo(groupingColumns: string[], columnDefs: SpotOnTanstackColDefs<any>[]) {
//   const groupings = groupingColumns
//     .filter((col) => col !== 'globalId')
//     .map((col) => {
//       const colDef = columnDefs.find((def) => def.accessorKey === col);
//       return { key: col, label: colDef?.so_oldHeader || col };
//     });

//   if (groupings.length === 0)
//     return "<div class='grouping-info single-order'><p>Each row shows details for a single order</p></div>";

//   const groupingDescriptions = groupings.map(
//     (g) => `<span class="highlight ${g.key.toLowerCase().replace(/[^a-z0-9]/g, '-')}">${g.label}</span>`,
//   );

//   const description = `
//     <div class='grouping-info'>
//       <div class='grouping-header'>
//         <span class='icon'>📊</span>
//         <h3>Understanding Your Grouped Data</h3>
//       </div>
//       <p class='grouping-description'>Your data is currently organized based on the columns you've selected. Here's what that means:</p>
//       <div class='grouping-visualization'>
//         <div class='group-box'>
//           ${groupingDescriptions.join(' + ')}
//         </div>
//         <div class='arrow'>➜</div>
//         <div class='row-box'>One Row</div>
//       </div>
//       <p class='grouping-explanation'>Each row in your table represents a unique combination of ${groupingDescriptions.join(
//         ', ',
//       )}.</p>
//       <div class='example-section'>
//         <h4>How this affects your view:</h4>
//         <ul>
//           <li>You're seeing summarized data for each unique group.</li>
//           <li>Numerical values (like sales or counts) are totaled for each group.</li>
//           <li>This view helps identify patterns across different combinations of these factors.</li>
//         </ul>
//       </div>
//       <div class='extra-details' style='display:none;'>
//         <h4>Example:</h4>
//         <p>Let's say you've grouped by ${groupingDescriptions[0]} and ${groupingDescriptions[1]}.</p>
//         <p>A row showing "${groupings[0].label}: Lunch, ${groupings[1].label}: Dine-in" would represent:</p>
//         <ul>
//           <li>All lunch orders that were dine-in</li>
//           <li>The total number of these orders</li>
//           <li>The total sales for these orders</li>
//           <li>Any other metrics, averaged or summed as appropriate</li>
//         </ul>
//       </div>
//     </div>
//   `;

//   return description;
// }

// function _getSimpleGroupingInfo(groupingColumns: string[], columnDefs: SpotOnTanstackColDefs<any>[]) {
//   const groupings = groupingColumns
//     .filter((col) => col !== 'globalId')
//     .map((col) => {
//       const colDef = columnDefs.find((def) => def.accessorKey === col);
//       return colDef?.so_oldHeader || col;
//     });

//   if (groupings.length === 0) {
//     return "<div class='grouping-info'><p>Each row represents a single, unique order.</p></div>";
//   }

//   const groupingList = groupings.map((g) => `<li>${g}</li>`).join('');

//   return `
//     <div class='grouping-info'>
//       <p><strong>Data Grouping:</strong> Each row in this table represents a group of orders sharing the same:</p>
//       <ul>${groupingList}</ul>
//       <p>Values in other columns show totals or averages for each group.</p>
//     </div>
//   `;
// }
