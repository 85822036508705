import React, { useState } from 'react';
import { Modal, Button, IconButton, colors } from 'spoton-lib';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/components/App/App.store';
import {
  closeColumnChooserModal,
  setChosenTemplate,
  setChosenColumns,
  setColumnOrder,
  saveColumnChooserConfig,
  setChosenDataSource,
  setCustomViewTitle,
  setDataSourceSelectionMode,
} from 'app/components/App/App.slice';
import { useNavigate } from 'react-router-dom';
import { BuildFromScratchModal } from '../BuildFromScratch/BuildFromScratchModal';

// import { getAssetPath } from 'utils/assets';

import styles from './TemplateChooser.module.scss';

import salesByOrderTypePreview from 'images/template-previews/sales-by-order-type-preview.png';
import orderListPreview from 'images/template-previews/order-list-preview.png';
import salesByCategoryPreview from 'images/template-previews/sales-by-category-preview.png';
import employeeSalesPreview from 'images/template-previews/employee-sales-preview.png';
import productMixPreview from 'images/template-previews/product-mix-preview.png';
import ordersPerDayPreview from 'images/template-previews/orders-per-day-preview.png';

type TemplateChooserProps = {
  open: boolean;
};

type Template = {
  id: string;
  value: string;
  label: string;
  description: string;
  explanation: string;
  imageUrl: string;
  color: string;
};

const templates: Template[] = [
  {
    id: '1',
    value: 'sales-by-order-type',
    label: 'Sales by Order Type',
    description: 'Analyze sales performance across different order types.',
    explanation:
      'Understand which order types (dine-in, takeout, delivery) are driving your revenue. This insight helps you optimize staffing, marketing, and operational focus to maximize profits across all channels.',
    imageUrl: salesByOrderTypePreview,
    color: '#FFA726',
  },
  {
    id: '2',
    value: 'order-list',
    label: 'Order List',
    description: 'Comprehensive view of all orders with key details.',
    explanation:
      "Get a bird's-eye view of all your orders in one place. This template helps you track order status, identify high-value customers, and manage fulfillment efficiently, ensuring smooth operations and happy customers.",
    imageUrl: orderListPreview,
    color: '#66BB6A',
  },
  {
    id: '3',
    value: 'sales-by-category',
    label: 'Sales by Category',
    description: 'Visualize sales performance across product categories.',
    explanation:
      'Identify your top-performing menu categories and underperforming ones. Use this data to refine your menu, focus on high-margin items, and make informed decisions about inventory and promotions.',
    imageUrl: salesByCategoryPreview,
    color: '#42A5F5',
  },
  {
    id: '4',
    value: 'employee-sales',
    label: 'Employee Sales',
    description: 'Track individual employee sales performance.',
    explanation:
      'Monitor and compare sales performance of your team members. This template helps you identify top performers, provide targeted coaching, and implement effective incentive programs to boost overall sales and service quality.',
    imageUrl: employeeSalesPreview,
    color: '#EC407A',
  },
  {
    id: '5',
    value: 'product-mix',
    label: 'Product Mix',
    description: 'Analyze the composition of your product sales.',
    explanation:
      'Gain insights into which menu items are selling well together. This information is crucial for creating effective combo deals, optimizing your menu layout, and planning promotional strategies to increase average order value.',
    imageUrl: productMixPreview,
    color: '#AB47BC',
  },
  {
    id: '6',
    value: 'orders-per-day',
    label: 'Orders Per Day',
    description: 'Visualize daily order volume trends.',
    explanation:
      'Track your daily order volumes to identify patterns and trends. This data is essential for resource planning, predicting busy periods, and understanding the impact of promotions or external factors on your business volume.',
    imageUrl: ordersPerDayPreview,
    color: '#26A69A',
  },
];

function getInitials(name: string): string {
  return name
    .split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 2)
    .toUpperCase();
}

export function TemplateChooser({ open }: TemplateChooserProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);
  const [showBuildFromScratch, setShowBuildFromScratch] = useState(false);

  const metadataBySource = useSelector((state: RootState) => state.app.metadataBySource);
  // const chosenDataSource = useSelector((state: RootState) => state.app.chosenDataSource);
  const availableTemplates = useSelector((state: RootState) => state.app.availableTemplates);

  const handleTemplateSelect = (templateId: string) => {
    setSelectedTemplate(templateId);
  };

  const handleUseTemplate = () => {
    if (selectedTemplate) {
      const selectedTemplateData = templates.find((t) => t.value === selectedTemplate);
      if (selectedTemplateData) {
        const templateId = selectedTemplateData.id;
        console.log('Using template ID:', templateId);
        dispatch(setDataSourceSelectionMode('useTemplates'));
        const newTitle = `Copy of ${selectedTemplateData.label}`;
        dispatch(setCustomViewTitle(newTitle));
        dispatch(setChosenTemplate(templateId));
        applyTemplateConfiguration(selectedTemplateData);
        dispatch(saveColumnChooserConfig());
        dispatch(closeColumnChooserModal());
        navigate('/custom-view/new');
      }
    }
  };

  const applyTemplateConfiguration = (template: Template) => {
    const selectedTemplate = availableTemplates.find((t) => t.value === template.value);
    if (!selectedTemplate) {
      console.error('Selected template configuration not found');
      return;
    }

    dispatch(setChosenDataSource(selectedTemplate.dataSource));

    const metadata = metadataBySource[selectedTemplate.dataSource];
    if (!metadata) {
      console.error('Metadata not found for selected data source');
      return;
    }

    const idToLabelMap = metadata.reduce(
      (acc, col) => {
        acc[col.accessorKey] = col.header;
        return acc;
      },
      {} as { [key: string]: string },
    );

    const chosenColumns = selectedTemplate.columns.map((col) => idToLabelMap[col] || col);
    const columnOrder = [...chosenColumns];

    dispatch(setChosenColumns(selectedTemplate.columns));
    dispatch(setColumnOrder(selectedTemplate.columns));

    console.log('Chosen columns:', chosenColumns);
    console.log('Column order:', columnOrder);
  };

  const handleBuildFromScratch = () => {
    setShowBuildFromScratch(true);
  };

  if (showBuildFromScratch) {
    return <BuildFromScratchModal open={open} />;
  }

  const selectedTemplateData = templates.find((t) => t.value === selectedTemplate);

  const toggleImageSize = () => {
    setIsImageEnlarged(!isImageEnlarged);
  };

  return (
    <Modal isOpen={open} className={styles.TemplateChooser}>
      <div className={styles.TemplateChooser_header}>
        <div className={styles.TemplateChooser_titleContainer}>
          <h2>Choose a Custom View Template</h2>
          <p className={styles.TemplateChooser_subtitle}>
            Choose a template to help create your custom view or{' '}
            <a href="#" onClick={handleBuildFromScratch}>
              build one from scratch
            </a>
            .
          </p>
        </div>
        <IconButton
          disableBorder
          size={24}
          color={colors.black}
          onClick={() => dispatch(closeColumnChooserModal())}
          name="CloseIcon"
          alt="Close"
        />
      </div>

      <div className={styles.TemplateChooser_content}>
        <div className={styles.TemplateList}>
          {templates.map((template) => (
            <div
              key={template.value}
              className={`${styles.TemplateItem} ${
                selectedTemplate === template.value ? styles.TemplateItem___selected : ''
              }`}
              onClick={() => handleTemplateSelect(template.value)}
            >
              <div className={styles.TemplateItem_icon} style={{ backgroundColor: template.color }}>
                {getInitials(template.label)}
              </div>
              <div className={styles.TemplateItem_content}>
                <h3>{template.label}</h3>
                <p>{template.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.TemplatePreview}>
          {selectedTemplateData ? (
            <>
              <div className={styles.TemplatePreview_header}>
                <h3 className={styles.TemplatePreview_title}>{selectedTemplateData.label}</h3>
                <Button onClick={handleUseTemplate} disabled={!selectedTemplate}>
                  Use Template
                </Button>
              </div>
              <div className={styles.TemplatePreview_enhancedDescription}>
                <h4>Key Benefits:</h4>
                <p>{selectedTemplateData.explanation}</p>
              </div>
              <div
                className={`${styles.TemplatePreview_image} ${
                  isImageEnlarged ? styles.TemplatePreview_image___enlarged : ''
                }`}
                onClick={toggleImageSize}
              >
                <img src={selectedTemplateData.imageUrl} alt={`${selectedTemplateData.label} preview`} loading="lazy" />
              </div>
              {isImageEnlarged && <p className={styles.TemplatePreview_clickInstruction}>Click image to reduce size</p>}
              {!isImageEnlarged && <p className={styles.TemplatePreview_clickInstruction}>Click image to enlarge</p>}
            </>
          ) : (
            <div className={styles.NoPreview}>
              <p>Select a template to see a preview</p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
