import { Column, Table } from '@tanstack/react-table';

import { Input } from 'spoton-lib';
import { useState } from 'react';
import { DATE_RANGE_FILTER } from './constants';
import moment from 'moment';

// TODO: Currently just the last 5 days are chosen
// but we could get a min and max date from the data and set that as the default

export function DateRangeFilter({
  column,
  serverMode,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
  serverMode: boolean;
}) {
  console.log('dateRangeFilter', serverMode);
  const getDate = ({ rangeValue, defaultDate }: { rangeValue: string; defaultDate: string }) => {
    const columnFilterValue = column.getFilterValue();

    if ((columnFilterValue as any)?.filterType === DATE_RANGE_FILTER) {
      return moment((columnFilterValue as any)?.params?.[rangeValue]).format('MM/DD/YYYY');
    }

    return defaultDate;
  };

  const [startDate, setStartDate] = useState(
    getDate({ rangeValue: 'startDate', defaultDate: moment().subtract(5, 'days').format('MM/DD/YYYY') }),
  );
  const [endDate, setEndDate] = useState(
    getDate({ rangeValue: 'endDate', defaultDate: moment().format('MM/DD/YYYY') }),
  );

  const setFilterValue = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const startDateValid = moment(startDate, 'MM/DD/YYYY', true).isValid();
    const endDateValid = moment(endDate, 'MM/DD/YYYY', true).isValid();

    if (startDateValid && endDateValid) {
      const filterValue = {
        filterType: DATE_RANGE_FILTER,
        params: { startDate: moment(startDate), endDate: moment(endDate) },
      };
      column.setFilterValue(filterValue);
    }
  };

  return (
    <>
      <Input
        label={'Start Date'}
        type="text"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
          setFilterValue({ startDate: e.target.value, endDate });
        }}
        name="startDate"
        placeholder="mm/dd/yyyy"
      />

      <Input
        label={'End Date'}
        type="text"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
          setFilterValue({ startDate, endDate: e.target.value });
        }}
        name="endDate"
        placeholder="mm/dd/yyyy"
      />
    </>
  );
}
