import { Column, Table } from '@tanstack/react-table';
import { TextFilter } from './TextFilter';
import { SetFilter } from './SetFilter';
import { Dropdown } from 'spoton-lib';
import { DATE_RANGE_FILTER, TEXT_FILTER, SET_FILTER, NUMERIC_RANGE_FILTER } from './constants';

import { NumberFilter } from './NumberFilter';
import { FilterType, SpotOnTanstackColDefs } from './types';
import { useState } from 'react';
import { DateRangeFilter } from './DateRangeFilter';

export function FilterFactory({
  column,
  table,
  serverMode,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
  serverMode: boolean;
}) {
  const columnFilterValue = column.getFilterValue();

  const soFilterFn = (column.columnDef as SpotOnTanstackColDefs<any>).filterFn;

  const getFilterMode = () => {
    if (columnFilterValue == null) {
      return soFilterFn;
    }

    if (Array.isArray(columnFilterValue)) {
      return NUMERIC_RANGE_FILTER;
    }

    if (typeof columnFilterValue === 'string') {
      return TEXT_FILTER;
    }

    return (columnFilterValue as any).filterType;
  };

  const [activeFilter, setActiveFilter] = useState<FilterType>(getFilterMode());

  const availableFilterTypes = [
    { label: 'Contains', value: TEXT_FILTER },
    { label: 'Is Between', value: NUMERIC_RANGE_FILTER },
    { label: 'Is One Of', value: SET_FILTER },
    // we shouldn't ever have NUMERIC_RANGE_FILTER and DATE_RANGE_FILTER in the same column
    // so its okay to have the same labels here.
    { label: 'Is Between', value: DATE_RANGE_FILTER },
  ];

  const soFilters = (column.columnDef as SpotOnTanstackColDefs<any>).so_filters;

  const filters =
    soFilters != null
      ? availableFilterTypes.filter((i) => {
          return soFilters.includes(i.value);
        })
      : [];

  let filter: JSX.Element | null = null;

  if (activeFilter === SET_FILTER) {
    filter = <SetFilter serverMode={serverMode} table={table} column={column} />;
  } else if (activeFilter === TEXT_FILTER) {
    filter = <TextFilter serverMode={serverMode} table={table} column={column} />;
  } else if (activeFilter === NUMERIC_RANGE_FILTER) {
    filter = <NumberFilter serverMode={serverMode} column={column} table={table} />;
  } else if (activeFilter === DATE_RANGE_FILTER) {
    filter = <DateRangeFilter serverMode={serverMode} table={table} column={column} />;
  } else {
    // should never happen
    filter = null;
  }

  return (
    <>
      <Dropdown
        isReadOnly={columnFilterValue != null}
        withDividers={true}
        label={(column.columnDef as any).so_oldHeader}
        options={filters}
        value={availableFilterTypes.find((i) => i.value === activeFilter)}
        onChange={(e: any) => {
          const value = e.value;
          setActiveFilter(value);
        }}
      />

      {filter}
    </>
  );
}
