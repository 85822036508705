export const predefinedCategories = {
  orders: {
    'Do Not Display': ['Location'],
    'Basic Information': [
      'Order Type',
      'Employee Full Name',
      'Order Number',
      'Table Number',
      'Station',
      'Employee Name',
      'Name',
    ],
    'Timing & Duration': [
      'Date',
      'Open Time',
      'Close Time',
      'Last Payment',
      'Time Open (Minutes)',
      'Order Closed',
      'Order Scheduled',
      'Order Reopened',
      'Item Created',
    ],
    'Time Period Categories': ['Day', 'Week', 'Month'],
    'Order Metrics': ['Items Count', 'Guest Count', 'Order Count'],
    'Status & Scheduling': ['Closed', 'Scheduled', 'Reopened', 'Gift Card', 'Voided'],
    'Financial Details': [
      'Net Sales Amount',
      'Payment Amount',
      'Discounts Amount',
      'Gratuity Amount',
      'Tip Amount',
      'Tax Amount',
      'Void Amount',
      'Fees',
      'Amount',
      'Discounts',
      'Gift Card Amount',
      'Modifiers Amount',
      'Inclusive Taxes Amount',
      'Exclusive Taxes Amount',
    ],
  },
  items: {
    'Do Not Display': ['Location'],
    'Item Information': ['Category', 'Parent Category', 'Name', 'Amount'],
    Quantity: [],
    'Order Details': [
      'Order Number',
      'Order Type',
      'Date',
      'Item Created',
      'Order Closed',
      'Order Scheduled',
      'Order Reopened',
      'Quantity',
    ],
    'Time Period Categories': ['Day', 'Week', 'Month'],
    Customizations: ['Modifications', 'Add-ons'],
    'Financial Information': [
      'Tax Amount',
      'Discounts',
      'Gift Card Amount',
      'Modifiers Amount',
      'Inclusive Taxes Amount',
      'Exclusive Taxes Amount',
    ],
    'Additional Information': ['Employee Name', 'Gift Card', 'Voided'],
  },

  payments: {
    'Do Not Display': [],
    'Payment Identification': [
      'Check ID',
      'Payment Name',
      'Payment Type',
      'Payment Status',
      'Order Number',
      'Order ID',
    ],
    'Transaction Details': ['Amount', 'Change Amount', 'Net Amount', 'Tips Amount', 'Tips Deduction Amount'],
    'Timing Information': ['Created Date Time', 'Payment Created At', 'Business Date'],
    'Time Period Categories': ['Month', 'Week', 'Day of Week'],
    'Order Information': ['Order Type', 'Order Details', 'Order Status'],
    'Status Indicators': ['Is Void', 'Is Complete'],
  },
  menu: {
    'Do Not Display': [],
    'Item Information': ['Name', 'Category', 'Parent Category'],
    'Sales Metrics': ['Quantity Sold', 'Total Sales'],
    'Financial Information': ['Discount Amount'],
    'Status Indicators': ['Unsold Flag'],
  },
};
