import { Row, Table } from '@tanstack/react-table';
import { Virtualizer } from '@tanstack/react-virtual';
import { MobileTableRenderer } from './SpotonDataTableMobile';
import { DesktopTableRenderer } from './SpotonDataTableDesktop';
import { CustomViewsTable } from '../CustomViewsTable/CustomViewsTable';

export function TableRenderer({
  table,
  rows,
  tableClassName,
  handleDragStart,
  handleDragOver,
  handleOnDrop,
  dragOverId,
  dragDirection,
  dragStart,
  rowVirtualizer,
  groupingColumns,
  isMobile,
  rowHeight,
  isSpecificCustomViewPage,
  pagination,
  chosenColumns,
  serverMode,
}: {
  table: Table<any>;
  tableClassName: string;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  handleOnDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  dragOverId: string;
  dragDirection: string;
  dragStart: string;
  rows: Row<any>[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  groupingColumns: string[];
  isMobile: boolean;
  rowHeight?: number;
  isSpecificCustomViewPage: boolean;
  pagination: { pageIndex: number; pageSize: number };
  chosenColumns: string[];
  serverMode: boolean;
}) {
  console.log('DEBUG999 table Render');

  if (isMobile) {
    return (
      <MobileTableRenderer
        table={table}
        rows={rows}
        rowVirtualizer={rowVirtualizer}
        tableClassName={tableClassName}
        groupingColumns={groupingColumns}
        pagination={pagination}
        chosenColumns={chosenColumns}
      />
    );
  }

  if (!isSpecificCustomViewPage) {
    return (
      <CustomViewsTable
        table={table}
        rows={rows}
        dragOverId={dragOverId}
        dragDirection={dragDirection}
        dragStart={dragStart}
        rowVirtualizer={rowVirtualizer}
        pagination={pagination}
      />
    );
  }

  return (
    <DesktopTableRenderer
      table={table}
      rows={rows}
      rowVirtualizer={rowVirtualizer}
      tableClassName={tableClassName}
      handleDragStart={handleDragStart}
      handleDragOver={handleDragOver}
      handleOnDrop={handleOnDrop}
      dragOverId={dragOverId}
      dragStart={dragStart}
      dragDirection={dragDirection}
      rowHeight={rowHeight}
      groupingColumns={groupingColumns}
      isSpecificCustomViewPage={isSpecificCustomViewPage}
      pagination={pagination}
      chosenColumns={chosenColumns}
      serverMode={serverMode}
    />
  );
}
