import { checkIsProdOrStaging } from './config.utils';

export const FAKE_CUSTOM_VIEWS_DATA = 'sr-5312-customviews-use-fake-data-tmp-2024-09';
export const SERVER_MODE_ENABLED = 'custom-views-server-mode';
export const FORCE_SERVER_MODE = 'custom-views-force-server-mode';

export const FAKE_DATA = 'custom-views-fake-data';

export const isFeatureFlagEnabled = (featureFlag: string): boolean => {
  if (checkIsProdOrStaging()) {
    if ((window as any).ALL_LD_FLAGS != null) {
      return (window as any).ALL_LD_FLAGS[featureFlag];
    }
  } else {
    if (featureFlag === SERVER_MODE_ENABLED) {
      return process.env['REACT_APP_SERVER_MODE_ENABLED'] === 'true';
    }

    if (featureFlag === FAKE_CUSTOM_VIEWS_DATA) {
      return process.env['REACT_APP_FAKE_CUSTOM_VIEWS_DATA'] === 'true';
    }

    if (featureFlag === FAKE_DATA) {
      return process.env['REACT_APP_FAKE_DATA'] === 'true';
    }

    if (featureFlag === FORCE_SERVER_MODE) {
      return process.env['REACT_APP_FORCE_SERVER_MODE'] === 'true';
    }
  }
};
