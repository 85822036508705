/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { AppDispatch } from 'app/components/App/App.store';
import { setCurrentCustomView } from 'app/components/App/App.slice';
import { CustomViewType } from '../../utils/customViews';
import styles from './CustomViews.module.scss';
import { Edit2, Share2, Trash2, MoreVertical } from 'lucide-react';
import moment from 'moment';

export function ViewCard({ view }: { view: CustomViewType }): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);

  const handleCardClick = (e: React.MouseEvent) => {
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('.CustomViewCard_moreButton') || e.target.closest('.CustomViewCard_options'))
    ) {
      return;
    }

    dispatch(setCurrentCustomView(view.id));
    navigate(`/custom-view/${view.id}`);
  };

  const handleOptionClick = (action: 'edit' | 'share' | 'delete') => {
    setShowOptions(false);
    switch (action) {
      case 'edit':
        // Handle edit
        break;
      case 'share':
        // Handle share
        break;
      case 'delete':
        // Handle delete
        break;
    }
  };

  // Format the date to MM-DD-YYYY
  const formattedDate = view.dateCreated ? moment(view.dateCreated).format('MM-DD-YYYY') : '';

  return (
    <div className={styles.CustomViewCard} onClick={handleCardClick}>
      <div className={styles.CustomViewCard_titleSection}>
        <h3 className={styles.CustomViewCard_title}>{view.name}</h3>
        <div className={styles.CustomViewCard_moreWrapper}>
          {/* <button
            className={styles.CustomViewCard_moreButton}
            onClick={(e) => {
              e.stopPropagation();
              setShowOptions(!showOptions);
            }}
          >
            <MoreVertical size={20} />
          </button> */}
          {showOptions && (
            <div className={styles.CustomViewCard_options}>
              <button onClick={() => handleOptionClick('edit')}>
                <Edit2 size={16} />
                Edit
              </button>
              <button onClick={() => handleOptionClick('share')}>
                <Share2 size={16} />
                Share
              </button>
              <button onClick={() => handleOptionClick('delete')}>
                <Trash2 size={16} />
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.CustomViewCard_description}>{view.description || 'No description provided'}</div>
      <div className={styles.CustomViewCard_info}>
        <div className={styles.CustomViewCard_label}>Data Source</div>
        <div className={styles.CustomViewCard_sourceText}>{view.dataSource}</div>
      </div>
      <div className={styles.CustomViewCard_info}>
        <div className={styles.CustomViewCard_label}>Created By</div>
        <div className={styles.CustomViewCard_sourceText} title={view.createdBy}>
          {view.createdBy || 'Unknown'}
        </div>
      </div>
      <div className={styles.CustomViewCard_footer}>
        <div className={styles.CustomViewCard_tag}>{view.categoryList?.[0] || 'Uncategorized'}</div>
        <div className={styles.CustomViewCard_date}>{formattedDate}</div>
      </div>
    </div>
  );
}
