/* eslint-disable @typescript-eslint/naming-convention */
import { valueFormatters } from './valueFormatters';
import { JUSTIFY_LEFT, JUSTIFY_RIGHT, MIN_CELL_WIDTH, MIN_CELL_WIDTH_MOBILE } from '../constants';
import { headerAlignmentFunc } from '../HeaderRenderer';
import { ColumnDefGeneratorParms, PrivateColumnDefs, ValueFormatterColumnDefGeneratorParms } from '../types';
import { isMobile } from 'features/common/utils/responsive.utils';

export function generateTanstackColumnDefs_valueFormatter({ columnDefs }: ValueFormatterColumnDefGeneratorParms) {
  const newColumnDefs = columnDefs.map((i) => {
    let formatterFunction = null;

    if (i.so_valueFormatter != null) {
      formatterFunction = valueFormatters[i.so_valueFormatter];
    }
    return {
      ...i,
      ...(formatterFunction != null && {
        cell: formatterFunction,
      }),
    };
  });

  return newColumnDefs;
}

export function generateTanstackColumnDefs_headerCellAlignment({
  columnDefs,
  dispatch,
}: ValueFormatterColumnDefGeneratorParms) {
  const newColumnDefs = columnDefs.map((i) => {
    let headerAlignmentFunction = null;

    if (i.so_justifyContent === JUSTIFY_LEFT || i.so_justifyContent === JUSTIFY_RIGHT) {
      headerAlignmentFunction = headerAlignmentFunc(dispatch);
    }

    // preserve the header label before it is overwritten with the function
    const so_oldHeader = i.header as string;

    const ret: PrivateColumnDefs<any> = {
      ...i,
      so_oldHeader,
      ...(headerAlignmentFunction != null && {
        header: headerAlignmentFunction,
      }),
    };
    return ret;
  });

  return newColumnDefs;
}

export function generateColumnDefs({ metadata, filters, dispatch, sortState }: ColumnDefGeneratorParms) {
  const columnDefsWithFilterFunctions = metadata.map((i: any) => {
    // active filter overrides the filterFn if it exists
    const activeFilter = filters.find((filter) => filter.id === i.accessorKey);

    let filterFn = null;

    if (activeFilter != null) {
      filterFn = activeFilter.value?.filterType;
    } else {
      filterFn = i.so_filterFn;
    }

    return {
      ...i,
      filterFn,
    };
  });

  const newColumnDefs = generateTanstackColumnDefs_valueFormatter({
    columnDefs: columnDefsWithFilterFunctions,
  });

  const newerColumnDefs = generateTanstackColumnDefs_headerCellAlignment({
    columnDefs: newColumnDefs,
    dispatch,
    sortState,
  });

  // for all non fixed width columns, we make sure all columns have minSize set. otherwise the user can resize the columns to be
  // small and make the header cell labels look awful.

  // Global Id column is what allows us to get a single grand total line when no columns are chosen.
  // We bascically do an implicit groupby on the globalId column, which will always have a value of 1
  // for all rows.

  const globalIdColumn = {
    accessorKey: 'globalId',
    header: 'Global Id',
    enableResizing: true,
    so_sortIndex: 10000,
    so_visibleByDefault: false,
  };

  return [
    globalIdColumn,
    ...newerColumnDefs.map((i) => {
      return {
        ...i,
        ...(i.so_fixedWidth == null && {
          minSize: isMobile() ? MIN_CELL_WIDTH_MOBILE : MIN_CELL_WIDTH,
        }),
      };
    }),
  ];
}
