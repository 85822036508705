/* eslint-disable @typescript-eslint/naming-convention */
import { SpotOnTanstackColDefs } from '../types';

export const valueFormatters = {
  numberFormatter: (params: any) => {
    const so_params = params?.column?.columnDef as SpotOnTanstackColDefs<any>;

    const options = so_params.so_INTLNumberFormatOptions;
    const locale = so_params.so_localeCode ?? 'en-US';
    const formatter = new Intl.NumberFormat(locale, options);

    return formatter.format(params.getValue());
  },
  dateFormatter: (params: any) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(params.getValue())) {
      // its already in the format we want
      return params.getValue();
    }

    const dateValue = new Date(params.getValue());
    const year = dateValue.getFullYear();
    const month = String(dateValue.getMonth() + 1).padStart(2, '0');
    const day = String(dateValue.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
};
