import React, { useState, useMemo } from 'react';
import { Modal, Button, IconButton, colors } from 'spoton-lib';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/components/App/App.store';
import {
  closeColumnChooserModal,
  setChosenDataSource,
  setChosenColumns,
  setColumnOrder,
  saveColumnChooserConfig,
  setDataSourceSelectionMode,
  setCustomViewTitle,
} from 'app/components/App/App.slice';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';

import styles from './BuildFromScratchModal.module.scss';
import { predefinedCategories } from './predefinedCategories';

type BuildFromScratchModalProps = {
  open: boolean;
};

type Column = {
  accessorKey: string;
  header: string;
  category: string;
};

export function BuildFromScratchModal({ open }: BuildFromScratchModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [selectedDataSource, setSelectedDataSource] = useState<string | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const metadataBySource = useSelector((state: RootState) => state.app.metadataBySource);

  const [error, setError] = useState<string | null>(null);

  const handleDataSourceSelect = (dataSource: string) => {
    setSelectedDataSource(dataSource);
    setSelectedColumns([]);
  };

  const handleColumnToggle = (column: string) => {
    setSelectedColumns((prev) => (prev.includes(column) ? prev.filter((col) => col !== column) : [...prev, column]));
  };

  const handleSelectAllCategory = (category: string) => {
    const categoryColumns = groupedColumns[category].map((col) => col.accessorKey);

    setSelectedColumns((prev) => {
      const isAllSelected = categoryColumns.every((col) => prev.includes(col));

      if (isAllSelected) {
        return prev.filter((col) => !categoryColumns.includes(col));
      } else {
        return [...new Set([...prev, ...categoryColumns])];
      }
    });
  };

  const handleClearAll = () => {
    setSelectedColumns([]);
  };

  async function handleCreateCustomView() {
    console.log('IN HANDLE CREATE CUSTOM VIEW');
    console.log('selected columns are: ', selectedColumns);
    if (selectedDataSource && selectedColumns.length > 0) {
      const filteredColumns = selectedColumns.filter((column) => {
        const columnData = Object.values(groupedColumns)
          .flat()
          .find((col) => col.accessorKey === column);
        return columnData && columnData.category !== 'Do Not Display';
      });
      dispatch(setDataSourceSelectionMode('useCustom'));
      dispatch(setCustomViewTitle('Give your View a Name'));
      dispatch(setChosenDataSource(selectedDataSource));
      dispatch(setChosenColumns(filteredColumns));
      dispatch(setColumnOrder(filteredColumns));
      dispatch(saveColumnChooserConfig());
      dispatch(closeColumnChooserModal());
      navigate('/custom-view/new');

      // KEEP IN: Drop promise approach to race condition for now
      // dispatch(setDataSourceSelectionMode('useCustom')).then(() => {
      //   dispatch(saveColumnChooserConfig());
      //   dispatch(closeColumnChooserModal());
      //   navigate('/custom-view/new');
      // });
    }
  }

  const groupedColumns = useMemo(() => {
    if (!selectedDataSource || !metadataBySource[selectedDataSource]) return {};

    const columns = metadataBySource[selectedDataSource];
    const grouped = {} as Record<string, Column[]>;

    // Use the correct predefined categories based on the selected data source
    const categories = predefinedCategories[selectedDataSource];

    // Create a reverse mapping of column headers to categories
    const headerToCategoryMap = Object.entries(categories).reduce(
      (acc, [category, columnNames]) => {
        (columnNames as string[]).forEach((name) => {
          acc[name.toLowerCase()] = category;
        });
        return acc;
      },
      {} as Record<string, string>,
    );

    const uncategorizedColumns: Column[] = [];
    columns.forEach((column) => {
      const categoryName = headerToCategoryMap[column.header.toLowerCase()];
      if (categoryName && categoryName !== 'Do Not Display') {
        if (!grouped[categoryName]) {
          grouped[categoryName] = [];
        }
        grouped[categoryName].push({ ...column, category: categoryName });
      } else if (!categoryName) {
        uncategorizedColumns.push(column);
      }
    });

    // Throw an error if there are uncategorized columns
    if (uncategorizedColumns.length > 0) {
      const uncategorizedHeaders = uncategorizedColumns.map((col) => col.header).join(', ');
      throw new Error(`The following columns are not assigned to any category: ${uncategorizedHeaders}`);
    }
    // delete grouped['Do Not Display'];
    return grouped;
  }, [selectedDataSource, metadataBySource]);

  const orderedCategories = useMemo(() => {
    if (selectedDataSource === 'items') {
      const categories = Object.keys(groupedColumns);
      const itemInfoIndex = categories.indexOf('Item Information');
      if (itemInfoIndex > -1) {
        categories.splice(itemInfoIndex, 1);
        categories.unshift('Item Information');
      }
      return categories;
    }
    return Object.keys(groupedColumns);
  }, [selectedDataSource, groupedColumns]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(selectedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Immediately update the state to trigger a re-render
    setSelectedColumns(items);

    // Force a re-flow to ensure smooth animation
    requestAnimationFrame(() => {
      const container = document.querySelector(`.${styles.selectedColumns}`);
      if (container) {
        container.classList.add(styles.animating);
        setTimeout(() => {
          container.classList.remove(styles.animating);
        }, 500);
      }
    });
  };

  try {
    return (
      <Modal isOpen={open} className={styles.BuildFromScratchModal}>
        <div className={styles.header}>
          <div>
            <h2 className={styles.title}>Build From Scratch</h2>
            <p className={styles.subtitle}>Create a custom view by selecting your data source and columns</p>
          </div>
          <IconButton
            disableBorder
            size={24}
            color={colors.gray[500]}
            onClick={() => dispatch(closeColumnChooserModal())}
            name="CloseIcon"
            alt="Close"
            className={styles.closeButton}
          />
        </div>

        <div className={styles.mainContainer}>
          <div className={styles.dataSourceSection}>
            <h3 className={styles.sectionHeader}>Select Data Source</h3>
            <div className={styles.dataSourceButtons}>
              <Button
                onClick={() => handleDataSourceSelect('orders')}
                className={`${styles.dataSourceBtn} ${selectedDataSource === 'orders' ? styles.selected : ''}`}
              >
                Orders
              </Button>
              <Button
                onClick={() => handleDataSourceSelect('items')}
                className={`${styles.dataSourceBtn} ${selectedDataSource === 'items' ? styles.selected : ''}`}
              >
                Items
              </Button>
              <Button
                onClick={() => handleDataSourceSelect('payments')}
                className={`${styles.dataSourceBtn} ${selectedDataSource === 'payments' ? styles.selected : ''}`}
              >
                Payments
              </Button>
              <Button
                onClick={() => handleDataSourceSelect('menu')}
                className={`${styles.dataSourceBtn} ${selectedDataSource === 'menu' ? styles.selected : ''}`}
              >
                Menu
              </Button>
            </div>
          </div>

          <div className={styles.columnContainer}>
            {orderedCategories.map((category) => {
              const columns = groupedColumns[category];
              const isAllSelected = columns.every((col) => selectedColumns.includes(col.accessorKey));
              return (
                <div key={category} className={styles.categorySection}>
                  <div className={styles.categoryHeader}>
                    <h4>{category}</h4>
                    <button onClick={() => handleSelectAllCategory(category)} className={styles.selectAllButton}>
                      {isAllSelected ? 'Deselect All' : 'Select All'}
                    </button>
                  </div>
                  <div className={styles.columnList}>
                    {columns.map((column) => (
                      <Button
                        key={column.accessorKey}
                        onClick={() => handleColumnToggle(column.accessorKey)}
                        className={`${styles.column} ${
                          selectedColumns.includes(column.accessorKey) ? styles.selected : ''
                        }`}
                      >
                        {column.header}
                      </Button>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.selectedColumnsSection}>
            <div className={styles.selectedColumnsHeader}>
              <span>Selected Columns ({selectedColumns.length})</span>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="selectedColumns" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`${styles.selectedColumns} ${snapshot.isDraggingOver ? styles.draggingOver : ''}`}
                  >
                    {selectedColumns.map((column, index) => {
                      const columnData = Object.values(groupedColumns)
                        .flat()
                        .find((col) => col.accessorKey === column);
                      return columnData ? (
                        <Draggable key={column} draggableId={column} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${styles.selectedColumn} ${snapshot.isDragging ? styles.dragging : ''}`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="16"
                                viewBox="0 -960 960 960"
                                width="16"
                                fill={colors.base50}
                                className={styles.dragHandle}
                              >
                                <path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z" />
                              </svg>
                              {columnData.header}
                              <IconButton
                                name="CloseIcon"
                                color={colors.gray[400]}
                                size={10}
                                className={styles.closeBtn}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleColumnToggle(column);
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ) : null;
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className={styles.actionButtons}>
            <Button onClick={handleClearAll} className={styles.clearAllButton}>
              <IconButton name="TrashIcon" color={colors.gray[500]} size={14} className={styles.clearAllIcon} />
              Clear All
            </Button>
            <Button
              onClick={handleCreateCustomView}
              disabled={!selectedDataSource || selectedColumns.length === 0}
              className={styles.customViewBtn}
            >
              <span className={styles.fullText}>Create Custom View</span>
              <span className={styles.shortText}>Create</span>
            </Button>
          </div>
        </div>
      </Modal>
    );
  } catch (e) {
    if (e instanceof Error) {
      setError(e.message);
    } else {
      setError('An unknown error occurred');
    }
    // Render an error state or return null
    return (
      <Modal isOpen={open} className={styles.BuildFromScratchModal}>
        <div className={styles.errorMessage}>
          {error || 'An error occurred while loading columns. Please try again later.'}
        </div>
      </Modal>
    );
  }
}
