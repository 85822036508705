export const PaginationReducer = {
  incrementPage: (state) => {
    if (state.currentCustomView.pageIndex < state.pageCount) {
      state.currentCustomView.pageIndex += 1;
    }

    if (state.currentCustomView.pageIndex >= state.pageCount) {
      state.nextPageEnabled = false;
    } else {
      state.nextPageEnabled = true;
    }

    if (state.currentCustomView.pageIndex <= 0) {
      state.previousPageEnabled = false;
    } else {
      state.previousPageEnabled = true;
    }
  },
  decrementPage: (state) => {
    if (state.currentCustomView.pageIndex >= 1) {
      state.currentCustomView.pageIndex -= 1;
    }

    if (state.currentCustomView.pageIndex <= 0) {
      state.previousPageEnabled = false;
    } else {
      state.previousPageEnabled = true;
    }

    if (state.currentCustomView.pageIndex >= state.pageCount) {
      state.nextPageEnabled = false;
    } else {
      state.nextPageEnabled = true;
    }
  },
  setPage: (state, action) => {
    if (action.payload >= state.pageCount) {
      state.nextPageEnabled = false;
    }

    if (action.payload <= 0) {
      state.previousPageEnabled = false;
    } else {
      state.previousPageEnabled = true;
    }
    state.currentCustomView.pageIndex = action.payload;

    if (state.currentCustomView.pageIndex == null) {
      state.previousPageEnabled = false;
      state.currentCustomView.pageIndex = 0;
    }

    if (state.pageCount === 0) {
      state.nextPageEnabled = false;
      state.previousPageEnabled = false;
    }

    if (state.currentCustomView.pageIndex + 1 < state.pageCount) {
      state.nextPageEnabled = true;
    } else {
      state.nextPageEnabled = false;
    }
  },
};
