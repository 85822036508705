// src/features/counter/appSlice.js
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import {
  customViews,
  dataSources,
  locations,
  saveCustomView,
  bulkRemoveCustomViews,
  bulkUpdateCustomViews,
  getRows,
  getDistinctColumnValues,
  getColumnMinMax,
} from './App.hunks';
import moment from 'moment';

import { FORCE_SERVER_MODE, isFeatureFlagEnabled, SERVER_MODE_ENABLED } from 'features/common/utils/featureFlags';
import { getDemoCustomViews, getCreatorName, getCurrentFormattedDate } from 'features/custom_views/utils/customViews';
import { PaginationReducer } from './Pagination.reducer';

type DataSourceSelectionMode = 'useTemplates' | 'useCustom';

type ColumnChooserMode = 'new' | 'edit';

const getColumnOrderFromMetadata = (metadata: any) => {
  const sorted = metadata.sort((a, b) => {
    if (a.so_sortIndex === b.so_sortIndex) {
      return 0;
    }

    return a.so_sortIndex > b.so_sortIndex ? 1 : -1;
  });

  return sorted.map((i: any) => i.accessorKey);
};

const getChosenColumnsFromMetadata = (metadata: any) => {
  return metadata.filter((i: any) => i.so_visibleByDefault).map((i: any) => i.accessorKey);
};

export const getDefaultStartDate = () => moment().subtract(30, 'days').toDate();

export const CUSTOM_VIEW_MODE = {
  CLIENT: 'client',
  SERVER: 'server',
  UNDEFINED: 'undefined',
};
export type CustomViewMode =
  | typeof CUSTOM_VIEW_MODE.CLIENT
  | typeof CUSTOM_VIEW_MODE.SERVER
  | typeof CUSTOM_VIEW_MODE.UNDEFINED;

// Initial state
const initialState = {
  appLoaded: false,

  // writing to this will trigger a refresh of the custom views
  customViewsLandingRefresh: 0,

  nextPageEnabled: true,
  previousPageEnabled: true,

  totalRows: 0,
  pageCount: 0,
  pageSize: 100,

  customViewsData: [],
  customViewsLoading: false,
  customViewsError: null,
  customViewsLoaded: false,

  saveCustomViewLoading: false,
  saveCustomViewData: [],
  saveCustomViewError: null,

  bulkUpdateCustomViewsLoading: false,
  bulkUpdateCustomViewsData: [],
  bulkUpdateCustomViewsError: null,

  bulkRemoveCustomViewsLoading: false,
  bulkRemoveCustomViewsData: [],
  bulkRemoveCustomViewsError: null,

  dataSourcesData: [],
  dataSourcesLoading: false,
  dataSourcesError: null,
  dataCatalog: {},

  availableDataSources: [],

  locationsData: [],
  locationsLoading: false,
  locationsError: null,

  getRowsData: [],
  getRowsLoading: false,
  getRowsError: null,

  chosenLocation: '',
  mode: CUSTOM_VIEW_MODE.UNDEFINED,

  // Column chooser state (when user saves this gets applied to currentCustomView) )
  // if the user has a currentCustomView (url params)
  // then this will be overwritten by the currentCustomView
  // but it will start out as the default template.

  dataSourceSelectionMode: 'useTemplates' as DataSourceSelectionMode,
  createNewCustomViewModalOpened: false,
  chosenDataSource: undefined,
  chosenTemplate: '1',
  availableTemplates: [],
  columnOrder: [],
  chosenColumns: [],
  columnChooserMode: 'new' as ColumnChooserMode,

  // this will be metadata by datasource
  metadataBySource: {},

  // this gets set when we select either an existing custom view, or a new custom view.
  currentCustomView: {
    id: null,
    startDate: null,
    dateCreated: null,
    endDate: null,
    name: '',
    dataSource: null,
    chosenColumns: null,
    description: null,
    emailList: [],
    categoryList: [],
    selectedRange: '',
    columnOrder: [],
    filters: [],
    columnSort: [],
    location: null,
    pageIndex: undefined,
    createdBy: null,
  },

  merchantId: null,

  getDistinctColumnValuesLoading: true,
  getDistinctColumnValuesError: null,
  getDistinctColumnValuesData: [],

  getColumnMinMaxLoading: true,
  getColumnMinMaxError: null,
  getColumnMinMaxData: [],
};

// Create the slice
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // indicates to getRows function that any changes to dataProps should trigger a fetch
    // we just dont want to call getRows until the custom view has been loaded.
    setAppLoaded: (state) => {
      state.appLoaded = true;
    },
    setClientMode: (state) => {
      //window.store.dispatch({ type: 'app/setClientMode', payload: null });
      state.mode = CUSTOM_VIEW_MODE.CLIENT;
    },
    setServerMode: (state) => {
      //window.store.dispatch({ type: 'app/setServerMode', payload: null });
      state.mode = CUSTOM_VIEW_MODE.SERVER;
    },
    setMerchantId: (state, action) => {
      state.merchantId = action.payload;
    },
    // for the pagination reducer we dont set state directly in server mode
    // it gets set in server mode after a successful fetch
    incrementPage: (state) => {
      if (state.mode === CUSTOM_VIEW_MODE.CLIENT) {
        return PaginationReducer.incrementPage(state);
      }
    },
    decrementPage: (state) => {
      if (state.mode === CUSTOM_VIEW_MODE.CLIENT) {
        return PaginationReducer.decrementPage(state);
      }
    },
    setPage: (state, action) => {
      if (state.mode === CUSTOM_VIEW_MODE.CLIENT) {
        return PaginationReducer.setPage(state, action);
      }
    },
    openDataSourceConfiguration: (state, action) => {
      state.createNewCustomViewModalOpened = true;

      state.columnChooserMode = action.payload;

      // we initialize chosenColumns
      // columnOrder for the modal
      if (action.payload === 'new') {
        // here we initialize the chosen columns and column order for the modal
        // by using either the metadata or the template

        if (state.dataSourceSelectionMode === 'useCustom') {
          // LIKELY DEAD CODE BECAUSE WE DEFAULT TO USE TEMPLATES when user chooses a new custom view
          // columns chooser state is derived  from metadata'
          try {
            const chosenDataSourceMetadata = state.metadataBySource[state.chosenDataSource];

            if (!chosenDataSourceMetadata) {
              console.error('No metadata found for chosen data source:', state.chosenDataSource);
              // Handle the case where metadata is not found, e.g., set default values or return early
              return; // or set a default value for metadata
            }

            const metadata = JSON.parse(JSON.stringify(chosenDataSourceMetadata));
            state.columnOrder = getColumnOrderFromMetadata(metadata);
            state.chosenColumns = getChosenColumnsFromMetadata(metadata);
          } catch (error) {
            console.error(
              'Failed to parse metadata for chosen data source:',
              state.metadataBySource[state.chosenDataSource],
            );
            console.error('Error details:', error);
            // Handle the error as needed, e.g., set metadata to a default value or throw an error
            // const metadata = {}; // Example of setting a default value
          }
        } else {
          // useTemplate mode.
          // column chooser state is derived from the template
          if (state.customViewsData.length === 0) {
            console.error('customViewsData is empty (it should have data), cannot find chosen template.');
            return;
          }
          const customView = state.customViewsData.find((i) => i.id === state.chosenTemplate);
          console.log('Custom View:', customView);
          state.chosenDataSource = customView?.dataSource;
          console.log('Chosen Data Source:', state.chosenDataSource);

          try {
            const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));

            // TODO: diff the column order from the metadata and the custom view
            // so that new columns can be appended to the end of the column order

            const defaultColumnOrder = getColumnOrderFromMetadata(metadata);

            // get all columns in default that are not in the custom views column order
            const customViewColumnOrderSet = new Set(customView.columnOrder);

            const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

            state.columnOrder = [...customView.columnOrder, ...diff];
            state.chosenColumns = customView?.chosenColumns;
          } catch (error) {
            console.error(
              'Failed to parse metadata for chosen data source:',
              state.metadataBySource[state.chosenDataSource],
            );
            console.error('Error details:', error);
            console.log('Full State is:', JSON.parse(JSON.stringify(state)));
            // Handle the error as needed, e.g., set metadata to a default value or throw an error
            // const metadata = {}; // Example of setting a default value
          }
        }
      } else {
        // user is editing an existing custom view
        // so we need to initialize the chosen columns and column order
        // from the currentCustomView

        try {
          const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.currentCustomView.dataSource]));

          const defaultColumnOrder = getColumnOrderFromMetadata(metadata);

          // get all columns in default that are not in the custom views column order
          const customViewColumnOrderSet = new Set(state.currentCustomView.columnOrder);
          const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

          state.columnOrder = [...state.currentCustomView.columnOrder, ...diff];
          state.chosenColumns = state.currentCustomView.chosenColumns;
          state.chosenDataSource = state.currentCustomView.dataSource;
          state.dataSourceSelectionMode = 'useCustom';
        } catch (error) {
          console.error(
            'Failed to parse metadata for chosen data source:',
            state.metadataBySource[state.currentCustomView.dataSource],
          );
          console.error('Error details:', error);
          // Handle the error as needed, e.g., set metadata to a default value or throw an error
          // const metadata = {}; // Example of setting a default value
        }
      }
    },
    setDataSourceSelectionMode: (state, action) => {
      state.dataSourceSelectionMode = action.payload;
      console.log('setting data source selection mode to: ', action.payload);
      if (state.dataSourceSelectionMode === 'useCustom') {
        // columns chooser state is derived  from metadata'
        try {
          const chosenDataSourceMetadata = state.metadataBySource[state.chosenDataSource];

          if (!chosenDataSourceMetadata) {
            console.error('No metadata found for chosen data source:', state.chosenDataSource);
            // Handle the case where metadata is not found, e.g., set default values or return early
            return; // or set a default value for metadata
          }

          const metadata = JSON.parse(JSON.stringify(chosenDataSourceMetadata));
          state.columnOrder = getColumnOrderFromMetadata(metadata);
          state.chosenColumns = getChosenColumnsFromMetadata(metadata);
        } catch (error) {
          console.error(
            'Failed to parse metadata for chosen data source:',
            state.metadataBySource[state.chosenDataSource],
          );
          console.error('Error details:', error);
          // Handle the error as needed, e.g., set metadata to a default value or throw an error
          // const metadata = {}; // Example of setting a default value
        }
      } else {
        if (state.chosenTemplate == null) {
          state.chosenTemplate = '1';
        }
        // column chooser state is derived from the template+metadata
        const customView = state.customViewsData.find((i) => i.id === state.chosenTemplate);

        state.chosenDataSource = customView?.dataSource;

        try {
          const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));
          const defaultColumnOrder = getColumnOrderFromMetadata(metadata);
          const customViewColumnOrderSet = new Set(customView.columnOrder);
          const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

          state.columnOrder = [...customView.columnOrder, ...diff];

          state.chosenColumns = customView?.chosenColumns;
        } catch (error) {
          console.error(
            'Failed to parse metadata for chosen data source:',
            state.metadataBySource[state.chosenDataSource],
          );
          console.error('Error details:', error);
          // Handle the error as needed, e.g., set metadata to a default value or throw an error
          // const metadata = {}; // Example of setting a default value
        }
      }
    },
    setName: (state, action) => {
      state.currentCustomView.name = action.payload;
    },

    setColumnSort: (state, action) => {
      state.currentCustomView.columnSort = action.payload ?? [];
    },

    setLocation: (state, action) => {
      state.currentCustomView.location = action.payload;
    },
    setChosenTemplate: (state, action) => {
      state.chosenTemplate = action.payload;
      if (state.customViewsData.length === 0) {
        console.error('customViewsData is empty, cannot find chosen template.');
        return;
      }
      const customView = state.customViewsData.find((i) => i.id === action.payload);

      // TODO: diff the column order from the metadata and the custom view
      // so that new columns can be appended to the end of the column order

      // get all columns in default that are not in the custom views column order

      state.chosenColumns = customView?.chosenColumns;
      state.chosenDataSource = customView?.dataSource;

      try {
        const metadata = JSON.parse(JSON.stringify(state.metadataBySource[state.chosenDataSource]));

        const defaultColumnOrder = getColumnOrderFromMetadata(metadata);

        const customViewColumnOrderSet = new Set(customView.columnOrder);

        const diff = defaultColumnOrder.filter((i) => !customViewColumnOrderSet.has(i));

        state.columnOrder = [...customView.columnOrder, ...diff];
      } catch (error) {
        console.error(
          'Failed to parse metadata for chosen data source:',
          state.metadataBySource[state.chosenDataSource],
        );
        console.error('Error details:', error);
        // Handle the error as needed, e.g., set metadata to a default value or throw an error
        // const metadata = {}; // Example of setting a default value
      }

      //state.columnOrder = customView?.columnOrder;
    },
    setChosenDataSource: (state, action) => {
      state.chosenDataSource = action.payload;
      try {
        const chosenDataSourceMetadata = state.metadataBySource[action.payload];

        if (!chosenDataSourceMetadata) {
          console.error('No metadata found for chosen data source:', action.payload);
          // Handle the case where metadata is not found, e.g., set default values or return early
          return; // or set a default value for metadata
        }

        const metadata = JSON.parse(JSON.stringify(chosenDataSourceMetadata));
        state.columnOrder = getColumnOrderFromMetadata(metadata);
        state.chosenColumns = getChosenColumnsFromMetadata(metadata);
      } catch (error) {
        console.error('Failed to parse metadata for chosen data source:', state.metadataBySource[action.payload]);
        console.error('Error details:', error);
        // Handle the error as needed, e.g., set metadata to a default value or throw an error
        // const metadata = {}; // Example of setting a default value
      }
    },
    setColumnOrder: (state, action) => {
      // when modal is opened we save to temporary state
      if (state.createNewCustomViewModalOpened) {
        state.columnOrder = action.payload;
      } else {
        // modal not open so we save to currentCustomView
        state.currentCustomView.columnOrder = action.payload;
      }
    },

    setChosenColumns: (state, action) => {
      state.chosenColumns = action.payload;
    },

    closeColumnChooserModal: (state) => {
      state.createNewCustomViewModalOpened = false;
    },
    saveColumnChooserConfig: (state) => {
      if (state.columnChooserMode === 'new') {
        // we just copy over the settings created in the modal to currentCustomView
        // and initialize custom view
        if (state.dataSourceSelectionMode === 'useCustom') {
          state.currentCustomView.id = 'new';

          state.currentCustomView.startDate = getDefaultStartDate();
          state.currentCustomView.endDate = moment().toDate();
          state.currentCustomView.createdBy = getCreatorName();
          state.currentCustomView.dateCreated = getCurrentFormattedDate();

          state.currentCustomView.name =
            state.dataSourceSelectionMode === 'useCustom'
              ? 'New Custom View (click to change)'
              : `Copy of ${state.chosenTemplate}`;

          state.currentCustomView.dataSource = state.chosenDataSource;
          state.currentCustomView.chosenColumns = state.chosenColumns;
          state.currentCustomView.description = '';
          state.currentCustomView.emailList = [];
          state.currentCustomView.categoryList = [];
          state.currentCustomView.selectedRange = 'custom';
          state.currentCustomView.columnOrder = state.columnOrder;
          state.currentCustomView.categoryList = [];
          state.currentCustomView.filters = [];
        } else {
          // user chose to use a template so copy the template over and override the chosen columns and column order
          // that were selected in the modal
          const template = state.customViewsData.find((i) => i.id === state.chosenTemplate);

          state.currentCustomView = {
            ...template,
            id: 'new',
            createdBy: getCreatorName(),
            dateCreated: getCurrentFormattedDate(),
            chosenColumns: state.chosenColumns,
            columnOrder: state.columnOrder,
            name: `Copy of ${template.name}`,
          };
        }
      } else {
        // editing a custom view

        const needsReload = state.chosenDataSource !== state.currentCustomView.dataSource;

        state.currentCustomView.dataSource = state.chosenDataSource;
        state.currentCustomView.chosenColumns = state.chosenColumns;
        state.currentCustomView.columnOrder = state.columnOrder;

        if (needsReload) {
          localStorage.setItem(
            'currentCustomView',
            JSON.stringify(
              {
                timestamp: Date.now(),
                data: { ...state.currentCustomView },
              },
              null,
              4,
            ),
          );

          window.location.assign(`/custom-views/custom-view/${state.currentCustomView.id}?useLocalStorage=1`);
        }
      }

      state.createNewCustomViewModalOpened = false;
    },
    setDateRange: (state, action) => {
      const { startDate, endDate, selectedRange } = action.payload;
      state.currentCustomView.startDate = startDate;
      state.currentCustomView.endDate = endDate;
      state.currentCustomView.selectedRange = selectedRange;
      state.currentCustomView.pageIndex = 0;
    },

    setFilters: (state, action) => {
      state.currentCustomView.filters = action.payload;
      state.currentCustomView.pageIndex = 0;
    },

    setCurrentCustomView: (state, action) => {
      if (typeof action.payload === 'string') {
        const customView = state.customViewsData.find((i) => i.id === action.payload);
        state.currentCustomView = customView;
      } else {
        state.currentCustomView = action.payload;
      }
    },
    setCustomViewTitle: (state, action: PayloadAction<string>) => {
      state.currentCustomView.name = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customViews.pending, (state) => {
        state.customViewsLoading = true;
        state.customViewsError = null;
      })
      .addCase(customViews.fulfilled, (state, action) => {
        console.log('DEBUG5 customViews.fulfilled action.payload=', action.payload);
        state.customViewsLoading = false;
        state.customViewsData = action.payload;
        state.customViewsError = null;
        state.customViewsLoaded = true;

        console.log('Fetched custom views data:', action.payload);

        if (state.customViewsData.length === 0) {
          console.error(
            'customViewsData is empty after fetching. This is unexpected and probably means clickhouse is not running/being connected to properly',
          );
        }

        if (state.currentCustomView.id == null) {
          const defaultCustomView = getDemoCustomViews(action.payload)[0];

          if (defaultCustomView != null) {
            state.currentCustomView = {
              ...defaultCustomView,
              id: undefined,
              pageIndex: 0,
              createdBy: getCreatorName(),
              dateCreated: getCurrentFormattedDate(),
            };

            state.dataSourceSelectionMode = 'useTemplates';
            state.currentCustomView.dataSource = defaultCustomView.dataSource;
            state.chosenDataSource = defaultCustomView.dataSource;
            state.chosenTemplate = defaultCustomView.id;
          }
        }

        const map = getDemoCustomViews(action.payload).map((template) => {
          return {
            label: template.name,
            value: template.id,
          };
        });

        state.availableTemplates = map;
      })

      .addCase(customViews.rejected, (state, action) => {
        state.customViewsLoading = false;
        state.customViewsError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(locations.pending, (state) => {
        state.locationsLoading = true;
        state.locationsError = null;
      })

      .addCase(locations.rejected, (state) => {
        state.locationsLoading = false;
        state.locationsError = true;
      })

      .addCase(locations.fulfilled, (state, action) => {
        state.locationsLoading = false;
        state.locationsData = action.payload; // Data from the fulfilled async thunk
        state.locationsError = null;
      })

      .addCase(bulkUpdateCustomViews.rejected, (state, action) => {
        state.bulkUpdateCustomViewsLoading = false;
        state.bulkUpdateCustomViewsError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(bulkUpdateCustomViews.pending, (state) => {
        state.bulkUpdateCustomViewsLoading = true;
        state.bulkUpdateCustomViewsError = null;
      })

      .addCase(bulkUpdateCustomViews.fulfilled, (state) => {
        state.customViewsLandingRefresh = state.customViewsLandingRefresh + 1;
        state.bulkUpdateCustomViewsLoading = false;
        state.bulkUpdateCustomViewsError = true;
      })

      .addCase(bulkRemoveCustomViews.rejected, (state, action) => {
        state.bulkRemoveCustomViewsLoading = false;
        state.bulkRemoveCustomViewsError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(bulkRemoveCustomViews.pending, (state) => {
        state.bulkRemoveCustomViewsLoading = true;
        state.bulkRemoveCustomViewsError = null;
      })

      .addCase(bulkRemoveCustomViews.fulfilled, (state) => {
        state.customViewsLandingRefresh = state.customViewsLandingRefresh + 1;
        state.bulkRemoveCustomViewsLoading = false;
        state.bulkRemoveCustomViewsError = false;
      })

      .addCase(saveCustomView.pending, (state) => {
        state.saveCustomViewLoading = true;
        state.saveCustomViewError = null;
      })

      .addCase(saveCustomView.fulfilled, (state, action) => {
        state.saveCustomViewLoading = false;
        // i dont think this will get used for anything but we'll keep it here for now
        state.saveCustomViewData = action.payload; // Data from the fulfilled async thunk

        state.saveCustomViewError = null;
        // for new custom views we need to set the id
        if (current(state.currentCustomView).id === 'new') {
          // NOTE: by changing the id, the custom view component rerenders
          // and detects this new id and navigates to the new custom view
          state.currentCustomView.id = action.payload.id;

          // here we just append the current to the stored custom views
          const viewToAdd = JSON.parse(JSON.stringify(state.currentCustomView));
          state.customViewsData = [...state.customViewsData, viewToAdd];
        } else {
          // the view was edited and saved so we need to update the custom views
          // with the new view
          const viewToAdd = JSON.parse(JSON.stringify(state.currentCustomView));
          const filteredCustomViews = state.customViewsData.filter((i) => i.id !== viewToAdd.id);
          state.customViewsData = [...filteredCustomViews, viewToAdd];
        }
      })

      .addCase(saveCustomView.rejected, (state, action) => {
        state.saveCustomViewLoading = false;
        state.saveCustomViewError = action.payload;
      })

      .addCase(dataSources.pending, (state) => {
        state.dataSourcesLoading = true;
        state.dataSourcesError = null;
      })
      .addCase(dataSources.fulfilled, (state, action) => {
        state.dataSourcesLoading = false;
        state.dataSourcesData = action.payload; // Data from the fulfilled async thunk
        state.dataSourcesError = null;

        state.metadataBySource = action.payload.reduce((acc, source) => {
          acc[source.name] = JSON.parse(source.metadata);

          return acc;
        }, {});

        // here we build the data catalog
        const dataCatalog = action.payload.reduce((acc, source) => {
          const obj = {
            name: source.name,
            getMeta: async () => {
              return Promise.resolve(JSON.parse(source.metadata));
            },
          };

          acc[source.name] = obj;
          return acc;
        }, {});

        state.availableDataSources = dataCatalog
          ? Object.keys(dataCatalog).map((i) => ({
              label: i,
              value: i,
            }))
          : [];

        state.dataCatalog = dataCatalog;
      })
      .addCase(dataSources.rejected, (state, action) => {
        state.dataSourcesLoading = false;
        state.dataSourcesError = action.payload as string; // Error from the rejected async thunk
      })

      .addCase(getRows.pending, (state) => {
        state.getRowsLoading = true;
        state.getRowsError = null;
      })
      .addCase(getRows.rejected, (state, action) => {
        state.getRowsLoading = false;
        state.getRowsError = action.payload as string; // Error from the rejected async thunk
      })
      .addCase(getRows.fulfilled, (state, action) => {
        console.log('DEBUG8 getRows ? start=', state.currentCustomView.pageIndex);
        console.log('DEBUG5 getRows.fulfilled action.payload=', action.payload);
        console.log('DEBUG8 postFetch customView', action.payload.customView);

        // state.totalRows = action.payload.totalRows;
        state.totalRows = Number(action.payload.totalRows);
        state.pageCount = action.payload.pageCount;

        if (isFeatureFlagEnabled(SERVER_MODE_ENABLED)) {
          state.mode = action.payload.serverMode ? CUSTOM_VIEW_MODE.SERVER : CUSTOM_VIEW_MODE.CLIENT;
        } else {
          state.mode = CUSTOM_VIEW_MODE.CLIENT;
        }

        PaginationReducer.setPage(state, { payload: action.payload.customView.pageIndex });

        if (state.mode === CUSTOM_VIEW_MODE.SERVER) {
          // in server mode we set the filters after the filter was applied on the server
          // setting the values before the fetch can result in a odd state where filters seem
          // like they are applied but they are not.
          // in short, we dont want to set the values until we know the server has applied them.

          state.currentCustomView.filters = action.payload.customView.filters;
        }

        state.getRowsLoading = false;

        // Here we create a synthetic globalId so we can perform a implicit groupby.
        // This is useful to get a single grand total row, when no grouping columns are chosen.
        const rowData = action.payload.rows?.map((i, idx) => ({ ...i, globalId: 1, rowIndex: idx }));

        state.getRowsLoading = false;
        state.getRowsData = rowData; // Data from the fulfilled async thunk
        state.getRowsError = null;

        if (isFeatureFlagEnabled(FORCE_SERVER_MODE)) {
          state.mode = CUSTOM_VIEW_MODE.SERVER;
        }

        console.log('DEBUG8 getRows ? END=', state.currentCustomView.pageIndex);
      })
      .addCase(getDistinctColumnValues.pending, (state) => {
        state.getDistinctColumnValuesLoading = true;
        state.getDistinctColumnValuesError = null;
      })
      .addCase(getDistinctColumnValues.rejected, (state, action) => {
        state.getDistinctColumnValuesLoading = false;
        state.getDistinctColumnValuesError = action.payload as string; // Error from the rejected async thunk
      })
      .addCase(getDistinctColumnValues.fulfilled, (state, action) => {
        state.getDistinctColumnValuesLoading = false;
        state.getDistinctColumnValuesData = action.payload; // Data from the fulfilled async thunk
        state.getDistinctColumnValuesError = null;
      })
      .addCase(getColumnMinMax.pending, (state) => {
        state.getColumnMinMaxLoading = true;
        state.getColumnMinMaxError = null;
      })
      .addCase(getColumnMinMax.rejected, (state, action) => {
        state.getColumnMinMaxLoading = false;
        state.getColumnMinMaxError = action.payload as string; // Error from the rejected async thunk
      })
      .addCase(getColumnMinMax.fulfilled, (state, action) => {
        state.getColumnMinMaxLoading = false;
        state.getColumnMinMaxData = action.payload; // Data from the fulfilled async thunk
        state.getColumnMinMaxError = null;
      });
  },
});

// Export the actions
export const {
  setAppLoaded,
  setMerchantId,
  setCurrentCustomView,
  setFilters,
  setDateRange,
  setDataSourceSelectionMode,
  setColumnSort,
  closeColumnChooserModal,
  saveColumnChooserConfig,
  setChosenColumns,
  setColumnOrder,
  setChosenDataSource,
  setChosenTemplate,
  openDataSourceConfiguration,
  setName,
  setLocation,
  setCustomViewTitle,
  setPage,
  incrementPage,
  decrementPage,
} = appSlice.actions;

// export const setDataSourceSelectionMode = (mode) => (dispatch) => {
//   return new Promise<void>((resolve) => {
//     dispatch({ type: 'setDataSourceSelectionMode', payload: mode });
//     resolve();
//   });
// };
