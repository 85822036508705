import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConfigVar } from 'features';
import {
  FAKE_DATA,
  FORCE_SERVER_MODE,
  SERVER_MODE_ENABLED,
  isFeatureFlagEnabled,
} from 'features/common/utils/featureFlags';
import moment from 'moment';
import { showToast } from 'spoton-lib';

// Helper function to check cache expiration
const isCacheExpired = (timestamp: number, duration: number): boolean => {
  return Date.now() > timestamp + duration;
};

export async function sendAsync(
  {
    method = 'GET',
    path,
    body,
    headers,
    cacheKey, // must be set if you want to cache the data
    cacheDuration, // must be set if you want to cache the data
    credentials,
    retries = 0,
  }: {
    method?: string;
    path: string;
    body?: any;
    headers?: any;
    credentials?: RequestCredentials;
    cacheKey?: string;
    cacheDuration?: number;
    retries?: number;
  },
  { rejectWithValue = null },
) {
  // does it exist in the cache already and is it still valid?

  const cacheEnabled = cacheKey != null && cacheDuration != null;

  if (cacheEnabled) {
    // Check localStorage
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (!isCacheExpired(timestamp, cacheDuration)) {
        return data; // Return cached data if it's not expired
      }
    }
  }

  const array = Array.from({ length: retries + 1 }, (_, i) => i);
  const retryMode = retries > 0;

  for (const idx of array) {
    try {
      const response = await fetch(`${path}`, { method, headers, body, credentials });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(JSON.stringify({ status: response.status, ...data }));
      }
      const data = await response.json();

      // Save to localStorage
      if (cacheEnabled) {
        localStorage.setItem(
          cacheKey,
          JSON.stringify({
            data,
            timestamp: Date.now(),
          }),
        );
      }

      return data as any; // Returns the user data which will be passed to `fulfilled`
    } catch (error) {
      if (retryMode) {
        if (idx === retries) {
          showToast({
            variant: 'danger',
            content: `There was an unexpected error. Retried ${idx} times. ${error.message} `,
            position: 'top-center',
            buttonText: '',
            limit: 2,
          });
          if (rejectWithValue) {
            return rejectWithValue(`Failed to fetch. ${error.message}`);
          }
        } else {
          console.log('DEBUG777 retrying idx=', idx);
        }
      } else {
        showToast({
          variant: 'danger',
          content: `There was an unexpected error. Retried ${idx} times. ${error.message} `,
          position: 'top-center',
          buttonText: '',
          limit: 2,
        });
        if (rejectWithValue) {
          return rejectWithValue(`Failed to fetch. ${error.message}`);
        }
      }
    }
  }
}

const getRowsFunction = async (customView, { getState, rejectWithValue = null }) => {
  try {
    const DATE_FORMAT = 'YYYYMMDD';
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    let startDateFmt;

    // If initial fetch is true, server will see if the results without the filters
    // are in range for client mode. If they are, it will return the results unfiltered
    // regardless of whether we sent filters or not.
    // If initial fetch is false server will return the results filtered by the filters.
    // and it always returns server mode in this case.

    // once in server mode, the only time we can get out of it is if we change the daterange,
    // datasoruce or load another custom view.

    const initialFetch = customView.initialFetch ?? false;

    console.log('DEBUG888 getRowsFunction initialFetch:', initialFetch);

    if (regex.test(customView.startDate)) {
      startDateFmt = customView.startDate.replace(/-/g, '');
    } else {
      startDateFmt = moment(customView.startDate).format(DATE_FORMAT);
    }

    let endDateFmt;

    if (regex.test(customView.endDate)) {
      endDateFmt = customView.endDate.replace(/-/g, '');
    } else {
      endDateFmt = moment(customView.endDate).format(DATE_FORMAT);
    }

    const serverModeEnabled = isFeatureFlagEnabled(SERVER_MODE_ENABLED);
    const forceServerMode = isFeatureFlagEnabled(FORCE_SERVER_MODE);

    const pathSuffix = isFeatureFlagEnabled(FAKE_DATA) ? '/-fake' : '';

    const path = `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views-data${pathSuffix}`;

    const url = `${path}`;

    const { dataSource, chosenColumns, filters, columnOrder, columnSort } = customView;

    const { pageSize } = getState().app;
    const { pageIndex } = customView;

    const body = JSON.stringify({
      dataSource,
      startDate: startDateFmt,
      endDate: endDateFmt,
      mode: serverModeEnabled ? 'server' : 'client',
      filters,
      columnOrder,
      columnSort: columnSort ?? [],
      pageIndex,
      pageSize,
      serverModeEnabled,
      forceServerMode,
      chosenColumns,
      initialFetch,
    });

    // make sure there's no way this is save to state
    customView.initialFetch = false;

    const data = await sendAsync(
      {
        method: 'POST',
        path: url,
        credentials: 'include',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      },
      { rejectWithValue: 'Failed to fetch.' },
    );

    // here is a subtle detail. the customView that we return here will get merged into state
    // in the getRowsFullfilled reducer. if we get here then modifications to custom view worked so
    // it we should update the state to reflect reality. if we write to the state first and some error
    // occured then we would be left with state the doesn't match reality.
    return { ...data, dataSource: customView.dataSource, customView };
  } catch (error) {
    if (rejectWithValue) {
      return rejectWithValue('Failed to fetch.'); // Custom error message
    }
  }
};

export const customViews = createAsyncThunk('customViews/fetchAll', sendAsync);
export const dataSources = createAsyncThunk('dataSources/fetchAll', sendAsync);
export const locations = createAsyncThunk('locations/fetchAll', sendAsync);
export const saveCustomView = createAsyncThunk('customViews/saveCustomView', sendAsync);

export const bulkRemoveCustomViews = createAsyncThunk('customViews/bulkRemove', sendAsync);
export const bulkUpdateCustomViews = createAsyncThunk('customViews/bulkUpdate', sendAsync);

export const getRows = createAsyncThunk('customViews/getRows', getRowsFunction);

export const getDistinctColumnValues = createAsyncThunk('customViews/distinctColumnValues', sendAsync);
export const getColumnMinMax = createAsyncThunk('customViews/columnMinMax', sendAsync);
