/* eslint-disable no-console */
import { Table } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { Input, Button, Checkbox, LoadingIndicator, TableError } from 'spoton-lib';

import styles from './SetFilter.module.scss';
import { StringToBooleanDict } from './types';
import { SET_FILTER } from './constants';
import { getDistinctColumnValues } from 'app/components/App/App.hunks';
import { getConfigVar } from 'features/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/components/App/App.store';
import { FAKE_DATA, isFeatureFlagEnabled } from 'features/common/utils/featureFlags';
import { setFilters } from 'app/components/App/App.slice';

// Handles getting the distinct values for a column and calls
// SetFilterWithDistinctValues to render the filter options
export function SetFilter({ column, serverMode, table }: { table?: Table<any>; column: any; serverMode: boolean }) {
  const dispatch = useDispatch<AppDispatch>();

  const filters = useSelector((state) => (state as any).app.currentCustomView.filters);
  const dataSource = useSelector((state) => (state as any).app.currentCustomView.dataSource);
  const startDate = useSelector((state) => (state as any).app.currentCustomView.startDate);
  const endDate = useSelector((state) => (state as any).app.currentCustomView.endDate);
  const loading = useSelector((state) => (state as any).app.getDistinctColumnValuesLoading);
  const error = useSelector((state) => (state as any).app.getDistinctColumnValuesError);
  const data = useSelector((state) => (state as any).app.getDistinctColumnValuesData);
  const chosenColumns = useSelector((state) => (state as any).app.currentCustomView.chosenColumns);

  useEffect(() => {
    if (serverMode) {
      const pathSuffix = isFeatureFlagEnabled(FAKE_DATA) ? '-fake' : '';

      dispatch(
        getDistinctColumnValues({
          method: 'POST',
          path: `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views-data/distinct-column-values${pathSuffix}`,
          body: JSON.stringify({ dataSource, startDate, endDate, filters, columnId: column.id, chosenColumns }),
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedUniqueValues = React.useMemo(() => {
    if (serverMode) return [];
    const ret = Array.from(column.getFacetedUniqueValues().keys()).sort();

    return Array.from(ret);
  }, [serverMode, column]) as string[];

  // eslint-disable-next-line no-constant-condition
  if (error) {
    return <TableError errorBody="Error loading column data" />;
  }

  if (!serverMode) {
    return (
      <SetFilterWithDistinctValues
        table={table}
        column={column}
        serverMode={serverMode}
        distinctValues={sortedUniqueValues}
      />
    );
  }

  // eslint-disable-next-line no-constant-condition
  if (loading) {
    return (
      <>
        <div className={styles.LoadingText}>Loading, column values...</div>

        <div className={styles.LoaderContainer}>
          <LoadingIndicator size="sm" greyAnimation />
        </div>
      </>
    );
  }

  return <SetFilterWithDistinctValues column={column} serverMode={true} distinctValues={data} />;
}

// This Set Filter handles both set and text Filter we should rename this
function SetFilterWithDistinctValues({
  column,
  serverMode,
  distinctValues,
}: {
  table?: Table<any>;
  column: any;
  serverMode: boolean;
  distinctValues: string[];
}) {
  const filters = useSelector((state) => (state as any).app.currentCustomView.filters);

  const columnFilterValue = column.getFilterValue();
  const dispatch = useDispatch<AppDispatch>();

  const cacheKey = columnFilterValue;

  const initColumnFilterState = () => {
    if (columnFilterValue != null && (columnFilterValue as any).filterType === SET_FILTER) {
      // here we merge the unchecked items from storage with sorted uniques
      // so that the set of possible values is dynamic but the unchecked values
      // remain unchecked.

      const { params } = columnFilterValue as any;

      const newState: StringToBooleanDict = {};
      distinctValues.forEach((current: any) => {
        newState['' + current] = params['' + current] != null ? params['' + current] : true;
      });

      return newState;
    }

    const newState: StringToBooleanDict = {};
    distinctValues.forEach((current: any) => {
      newState['' + current] = true;
    });

    return newState as StringToBooleanDict;
  };

  const initialState = React.useMemo(
    () => initColumnFilterState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cacheKey],
  );

  // state for set filter is just dictionary of columnname->true OR false values
  const [colSet, setColSet] = useState<StringToBooleanDict>(initialState);

  const [checkboxFilter, setCheckboxFilter] = useState('');

  const visibleOptions =
    checkboxFilter !== '' ? Object.keys(colSet).filter((i) => i.includes(checkboxFilter)) : Object.keys(colSet);

  function getUncheckedColumnMap(columnCheckedMap: StringToBooleanDict) {
    const uncheckedColumns = Object.keys(columnCheckedMap).filter((i) => columnCheckedMap[i] === false);

    const uncheckedColumnsMap: StringToBooleanDict = {};

    uncheckedColumns.forEach((current: any) => {
      uncheckedColumnsMap['' + current] = false;
    });

    return uncheckedColumnsMap;
  }

  const valueChecked = (col: any) => {
    return ({ target }: { target: any }) => {
      const newValWithTrues = {
        ...colSet,
        [col]: target.checked,
      };

      const newVal = getUncheckedColumnMap({
        ...colSet,
        [col]: target.checked,
      });

      const filterValue = { filterType: SET_FILTER, params: newVal };

      if (!serverMode) {
        column.setFilterValue(filterValue);
      } else {
        // notice we dont bother setting the filter value here
        // we dont want the table to know about the filters in serverMode

        const filterIdx = filters.findIndex((i: any) => i.id === column.id);

        if (filterIdx >= 0) {
          const filtersCopy = [...filters];
          filtersCopy[filterIdx] = { id: column.id, value: filterValue };
          dispatch(setFilters(filtersCopy));
        } else {
          const newFilters = [...filters, { id: column.id, value: filterValue }];
          dispatch(setFilters(newFilters));
        }
      }
      setColSet(newValWithTrues);
    };
  };

  function renderRow({ index, key, style }: { index: number; key: any; style: any }) {
    const label = visibleOptions[index];
    return (
      <div key={key} style={style}>
        <Checkbox
          label={label}
          data-testid="MultiEditCheckbox"
          checked={colSet[label] === true}
          className={`${styles.Checkbox}`}
          onChange={valueChecked(label)}
        />
      </div>
    );
  }

  const clearState = () => {
    // update colSet so that everything is checked
    const newColSet: StringToBooleanDict = {};
    Object.keys(colSet).forEach((current: any) => {
      newColSet['' + current] = true;
    });

    setColSet(newColSet);

    const filterValue = {
      filterType: SET_FILTER,
      params: {},
    };

    column.setFilterValue(filterValue);
  };

  const unCheckAll = () => {
    // update colSet so that everything is unchecked
    const newColSet: StringToBooleanDict = {};
    Object.keys(colSet).forEach((current: any) => {
      newColSet['' + current] = false;
    });

    const filterValue = {
      filterType: SET_FILTER,
      params: newColSet,
    };

    column.setFilterValue(filterValue);

    setColSet(newColSet);
  };

  return (
    <>
      <div className={styles.PopoverContentContainer}>
        <Input
          label={''}
          type="text"
          value={checkboxFilter}
          onChange={(e) => {
            setCheckboxFilter(e.target.value);
          }}
          name="customViewName"
          placeholder="Filter options..."
        />

        <div
          className={`${styles.CheckboxContainer} ${
            visibleOptions.length < 40 ? '' : styles['CheckboxContainer___fixedHeight']
          }`}
        >
          {visibleOptions.length < 40 ? (
            visibleOptions.map((i) => {
              return (
                <div key={i} style={{ display: 'flex' }}>
                  <Checkbox
                    label={i}
                    data-testid="MultiEditCheckbox"
                    checked={colSet[i] === true}
                    className={`${styles.Checkbox}`}
                    onChange={valueChecked(i)}
                  />
                </div>
              );
            })
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={32}
                  rowRenderer={renderRow}
                  rowCount={visibleOptions.length}
                  overscanRowCount={100}
                />
              )}
            </AutoSizer>
          )}
        </div>

        <div className={styles.CheckboxSelectButtons}>
          <Button
            variant="ghost"
            className={`${styles.ClearButton}`}
            style={{ width: '100%' }}
            name="ClearFilter"
            onClick={() => {
              clearState();
            }}
          >
            {'Select All'}
          </Button>
          <Button
            variant="ghost"
            className={`${styles.ClearButton} ${styles.ClearButton__right}`}
            style={{ width: '100%' }}
            name="ClearFilter"
            onClick={() => {
              unCheckAll();
            }}
          >
            {'Deselect All'}
          </Button>
        </div>
      </div>
    </>
  );
}
