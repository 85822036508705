import { datadogRum } from '@datadog/browser-rum';
import { Middleware } from '@reduxjs/toolkit';

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
const loggerMiddleware: Middleware = (store) => (next) => (action) => {
  const actionStringified = JSON.stringify(action, null, 4);
  const merchant = store.getState().app.merchantId ?? 'unknown';

  datadogRum.addAction(`reduxLog-${merchant}`, { action: actionStringified });

  console.log('DEBUG5 Dispatching action:', action, store.getState());
  return next(action);
};

export default loggerMiddleware;
