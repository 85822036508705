import moment from 'moment';

export type CustomViewType = {
  location: string;
  name: string;
  id?: string;
  startDate: Date;
  selectedRange: string;
  endDate: Date;
  dataSource: string;
  dateCreated: string;
  createdBy: string;
  chosenColumns: string[];
  description: string;
  emailList: string[];
  categoryList: string[];
  columnOrder: string[];
  filters: any[];
  columnSort: any[];
};

export const isTemplateId = (id) => {
  return id != null && Number.isFinite(Number(id));
};

export type StringToBooleanDict = {
  [key: string]: boolean;
};

export type IdToCustomViewMetadataDict = {
  [key: number]: CustomViewType;
};

export const getDemoCustomViews = (customViewData: CustomViewType[]): CustomViewType[] => {
  if (Array.isArray(customViewData)) {
    return customViewData.filter((i) => isTemplateId(i.id));
  }
};

export const getCustomView = (id: string, customViewData: CustomViewType[]): CustomViewType | undefined => {
  if (customViewData) {
    return customViewData.find((i: CustomViewType) => i.id === id);
  }
};

export const getCreatorName = (): string => {
  if (process.env.NODE_ENV === 'development') {
    return 'local';
  }

  try {
    const dataLayer = window?.dataLayer;
    if (!dataLayer || !Array.isArray(dataLayer) || !dataLayer[0]) {
      return '';
    }

    return dataLayer[0]['merchantUsername'] || '';
  } catch {
    return '';
  }
};

export const getCurrentFormattedDate = (): string => {
  return moment().format('MM-DD-YYYY');
};
