import { SpotOnTanstackColDefs } from '../components/SpotonDataTable/types';

export function getGroupingColumns(selectedColumns: string[], coldefs: SpotOnTanstackColDefs<any>[]) {
  const chosenColumnSet = new Set(selectedColumns);
  const groupingColumns = coldefs
    .filter((i) => chosenColumnSet.has(i.accessorKey as string))
    .filter((i) => {
      if (i.so_uniq_val_no_grouping_applied) {
        return false;
      }
      return i.aggregationFn == null;
    })
    .map((i) => i.accessorKey as string);

  if (coldefs.some((i) => i.so_uniq_val_no_grouping_applied && chosenColumnSet.has(i.accessorKey as string))) {
    return [];
  }

  if (groupingColumns.length === 0 || groupingColumns.includes('globalId')) {
    return ['globalId'];
  }
  return ['globalId', ...groupingColumns];
}

// Use this segment to build upon the actual setnences to use for grouping
//
// export function getGroupingSentences(selectedColumns: string) {}
